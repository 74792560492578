<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <!--<v-toolbar-title>{{ $t("OrderListView.page_title") }}</v-toolbar-title>-->
      <v-toolbar-title>{{
        $t("ReportWorkshiftSummary.page_title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-calendar-multiple</v-icon>
    </v-toolbar>
    <v-row justify="end">
      <v-spacer></v-spacer>
      <v-col v-show="$vuetify.breakpoint.smAndDown" cols="12" md="3">
        <v-switch
          v-model="mobileBreakpointSwitch"
          :label="$t('ReportWorkshiftSummary.mobileBreakpointSwitch_label')"
          hide-details
        ></v-switch> </v-col
      ><v-col cols="12" md="3">
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          :label="$t('ReportWorkshiftSummary.selectedHeaders_label')"
          multiple
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text caption"
              >(+{{ selectedHeaders.length - 2 }})</span
            >
          </template>
        </v-select></v-col
      >
    </v-row>
    <v-row class="my-1" align="center">
      <v-col cols="12" md="10">
        <datetime-range-picker filled v-model="dateRange" eager>
        </datetime-range-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="info" large @click="getAll()">
          <v-icon left dark> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col></v-row
    >
    <v-data-table
      :loading="loadingData"
      :headers="showHeaders"
      :items="gridItems"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, 250, -1],
      }"
      class="elevation-1"
      :search="filter.search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      dense
      :mobile-breakpoint="mobileBreakpointTable"
    >
      <template v-slot:[`body.prepend`]>
        <tr>
          <td :hidden="driverIdHidden">
            <v-text-field
              v-model="filter.driverId"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportWorkshiftSummary.driverId')"
            ></v-text-field>
          </td>
          <td :hidden="driverFirstNameHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.DriverFirstName')"
              v-model="filter.driverFirstName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td :hidden="driverLastNameHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.DriverLastName')"
              v-model="filter.driverLastName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td :hidden="breakCounterHidden"></td>
          <td :hidden="breakDurationHidden"></td>
          <td :hidden="mileagesHidden"></td>
          <td :hidden="durationHidden"></td>
          <td :hidden="distanceHidden"></td>
          <td :hidden="netDurationHidden"></td>
        </tr>
      </template>
    </v-data-table>
    <v-spacer></v-spacer>
    <br />
    <!--<download-excel
      :data="gridItems"
      worksheet="txm_export"
      name="txm_expor.xls"
      ><v-btn color="info" outlined> Download Data</v-btn>
    </download-excel>-->
    <export-data
      :jsonData="gridItems"
      :jsonFields="jsonFields"
      :outlined="true"
      dense
    >
    </export-data>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import ExportData from "../components/ExportData.vue";
import {
  Status,
  Type,
  Source,
  PaymentType,
  StatusColor,
  TypeColor,
  SourceColor,
} from "../enums/OrderEnum";

export default {
  components: {
    ExportData,
  },
  data() {
    return {
      module: moduleDefaultSettings,
      Status,
      Type,
      Source,
      PaymentType,
      StatusColor,
      TypeColor,
      SourceColor,
      moment: moment,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      //ID taxisluzby a dispecera
      dispatcherId: null,
      taxiserviceId: null,

      //premenne pre vyhladavanie/filter. Podla: https://codepen.io/rveruna/pen/gVBJmq
      filter: {
        search: "",
        id: "",
        driverFirstName: "",
        driverLastName: "",
        driverId: "",
      },

      // gridHeaders: [
      //   {
      //     text: "Id",
      //     align: "start",
      //     sortable: false,
      //     value: "id",
      //   },
      //   { text: this.$t("Status"), value: "status" },
      //   { text: this.$t("Type"), value: "type" },
      //   { text: this.$t("Note"), value: "note" },
      //   {
      //     text: this.$t("CreatedAt"),
      //     value: "createdAt",
      //   },
      // ],
      gridItems: [],
      headers: [],
      selectedHeaders: [],
      headersList: [], //lista stlpcov pre vyber stlpcov
      sortBy: null,
      sortDesc: true,
      defaultHiddenColumns: ["driverId"],
      slotList: [
        "driverId",
        "driverFirstName",
        "driverLastName",
        "breakCounter",
        "breakDuration",
        "mileages",
        "duration",
        "distance",
        "netDuration",
      ],
      //nazvy stlpcov v tabulke, aby sa skryvali filtrovacie polia
      driverIdHidden: false,
      driverFirstNameHidden: false,
      driverLastNameHidden: false,
      breakCounterHidden: false,
      breakDurationHidden: false,
      mileagesHidden: false,
      durationHidden: false,
      distanceHidden: false,
      netDurationHidden: false,

      //Zoznam stlpcov pre export do excelu
      jsonFields: {
        DRIVER_ID: { field: "driverId" },
        DRIVER_FIRST_NAME: { field: "driverFirstName" },
        DRIVER_LAST_NAME: { field: "driverLastName" },
        BREAK_COUNTER: { field: "breakCounter" },
        BREAK_DURATION: { field: "breakDuration" },
        MILEAGES: { field: "mileages" },
        DURATION: { field: "duration" },
        DISTANCE: { field: "distance" },
        NET_DURATION: { field: "netDuration" },
      },

      loadingData: false,
      //DateRangePicker - vyber rozhadu datumov pre zoznam
      dateRange: [], //["2021-07-22","2021-07-24"],

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
    var headersMap = this.headersMap;
    this.headers = Object.values(headersMap);
    //Potrebujeme sa zbavit hodnoty "divide" v headersList. Ale upravu som nepozil vo v-select, musel som hodnotu odstranit z headerMap. Doplnala do v-select oddelovace miesto textu. Mozno sa este zamyslim co s tym urobit.
    //divide bolo treba za ucelom formatovania v tabulke, aby boli oddlene stlpce ciarami
    /*headersMap.forEach((header) => {
      this.headersList.push({
        text: header.text,
        value: header.value,
      });
    });*/
    //this.headersList = Object.values(headersMap);

    this.selectedHeaders = this.headers;

    //Zistenie zoznamu skrytych stlpcov z Cookies
    var tempHiddenColumns = this.getCookie(
      "actualHiddenColumnsReportWorkshiftSummary"
    )
      ? JSON.parse(this.getCookie("actualHiddenColumnsReportWorkshiftSummary"))
      : [];
    //ak existuje cookie, nacitat ulozenu hodnotu (zoznam stlpcov, ktore sa maju skryt)
    if (tempHiddenColumns.length > 0) {
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !tempHiddenColumns.includes(el.value)
      );
    } else {
      //ak neexistuje cookie, nastavit defaultne hodnoty
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !this.defaultHiddenColumns.includes(el.value)
      );
    }
  },

  mounted() {
    //ID prihlaseneho dispecera
    this.dispatcherId = this.$store.state.user.id;
    //console.log("dispatcherId", dispatcherId);
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    //console.log("mounted - this.dateRange", this.dateRange);

    var mobileBreakpointSwitch = this.getCookie("mobileBreakpointSwitch");
    if (mobileBreakpointSwitch && mobileBreakpointSwitch != null) {
      this.mobileBreakpointSwitch =
        mobileBreakpointSwitch.toLowerCase() == "true";
    } else {
      this.mobileBreakpointSwitch = false;
    }

    //Niekedy pri prvom nacitany stranky, nie je hned vyrenderovany sub component, tak pocat 2s. Inak to neviem zatial vyriesit.
    if (this.dateRange[0] == undefined) {
      setTimeout(() => {
        this.getAll();
      }, 2000);
    } else {
      this.getAll();
    }
  },

  watch: {
    showHeaders: function () {
      //ak sa zobrazi hlavicka stlpca, zobrazit aj filter
      var tempShowHearders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          tempShowHearders.some(function (e) {
            return e.value == item;
          })
        ) {
          this[item + "Hidden"] = false;
        } else {
          this[item + "Hidden"] = true;
        }
      });
    },
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },

  computed: {
    showHeaders() {
      //console.log("computed - this.selectedHeaders", this.selectedHeaders);
      //ak sa zobrazi hlavicka stlpca, zobrazit aj filter
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },

    headersMap() {
      return [
        {
          text: this.$t("OrderListView.DriverId"),
          //value: "driver.id",
          value: "driverId",
          filter: (value) => {
            if (!this.filter.driverId) return true;
            if (value) return value === parseInt(this.filter.driverId);
          },
        },
        {
          text: this.$t("OrderListView.DriverFirstName"),
          align: "start",
          sortable: true,
          value: "driverFirstName",
          filter: (value) => {
            if (!this.filter.driverFirstName) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.driverFirstName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.DriverLastName"),
          align: "start",
          sortable: true,
          value: "driverLastName",
          filter: (value) => {
            if (!this.filter.driverLastName) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.driverLastName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.BreakCounter"),
          value: "breakCounter",
        },
        {
          text: this.$t("OrderListView.BreakDuration"),
          value: "breakDuration",
        },

        {
          text: this.$t("OrderListView.Mileages"),
          value: "mileages",
        },
        {
          text: this.$t("OrderListView.Duration"),
          value: "duration",
        },
        {
          text: this.$t("OrderListView.Distance"),
          value: "distance",
        },
        {
          text: this.$t("OrderListView.NetDuration"),
          value: "netDuration",
        },
      ];
    },
  },

  methods: {
    getAll() {
      //Ak nie je povoleny modul s reportmi, nenaciata ziadne data
      if (this.module.report == false) {
        this.gridItems = [];
        //this.$router.push({ name: "dashboard" });
        //snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgModuleReportNotEnabled");
        this.snackbar.color = "error";
        return;
      }
      //console.log("getAll - dateRange ", this.dateRange);
      //Ulozit si nastavenie rozsahu datumov do cookies
      //this.setCookie("dateRange", JSON.stringify(this.dateRange), 1);
      //console.log("getAll - setCookie - dateRange",JSON.stringify(this.dateRange));
      this.setHiddenColumnListCookie();
      if (this.checkDateRange() == false) {
        return;
      }
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/report/worklog-summary?from=${this.dateRange[0]}&to=${this.dateRange[1]}`
        )
        .then((response) => {
          //console.log("response", response);
          this.loadingData = false;
          this.gridItems = response.data;
          //remove duplicates from array by id
          /*this.gridItems = this.gridItems.filter(
            (thing, index, self) =>
              index ===
              self.findIndex((t) => t.id === thing.id && t.id !== undefined)
          );*/
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    checkDateRange() {
      //dateRange moze byt iba v rozsahu 2 mesiacov
      if (this.dateRange[0] == null || this.dateRange[1] == null) {
        alert("Zadajte rozsah dátumov");
        return false;
      } else {
        var dateFrom = new Date(this.dateRange[0]);
        var dateTo = new Date(this.dateRange[1]);
        var diffTime = Math.abs(dateTo - dateFrom);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        //console.log("diffDays", diffDays);
        if (diffDays > 63) {
          alert("Rozsah dátumov môže byť maximálne 2 mesiace");
          return false;
        } else {
          return true;
        }
      }
    },
    setHiddenColumnListCookie() {
      //aktualny zoznam poli, ktore sa nemaju zobrazit, ulozit do cookies
      var actualHiddenColumns = [];
      //compare slotList and showHeaders
      var tempShowHeaders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          !tempShowHeaders.some(function (e) {
            return e.value == item;
          })
        ) {
          actualHiddenColumns.push(item);
        }
      });
      this.setCookie(
        "actualHiddenColumnsReportWorkshiftSummary",
        JSON.stringify(actualHiddenColumns),
        30
      );
    },
    getStatusColor(status) {
      return this.StatusColor[status];
    },
    getTypeColor(type) {
      return this.TypeColor[type];
    },
    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //Vymazanie cookie
    eraseCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },

    //Konverzia mesiaca na nazov
    toMonthName(monthNumber) {
      //console.log("toMonthName: " + monthNumber);
      if (isNaN(monthNumber)) {
        //Ak nie je cislo, konvertova na cislo
        monthNumber = parseInt(monthNumber);
      }
      var localeLanguage = this.$store.state.appLocale;
      if (localeLanguage == "en" || localeLanguage == "us") {
        localeLanguage = "en-US";
      } else if (localeLanguage == "cs" || localeLanguage == "cz") {
        localeLanguage = "cs-CZ";
      } else if (localeLanguage == "sk") {
        localeLanguage = "sk-SK";
      } else {
        localeLanguage = "en-US";
      }
      //console.log("localeLanguage", localeLanguage);
      const date = new Date();
      date.setMonth(monthNumber - 1);
      //console.log("date", date);
      return date.toLocaleString(localeLanguage, {
        month: "long",
      });
    },
  },
};
</script>
