<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("ReportWorkshift.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-calendar-clock</v-icon>
    </v-toolbar>
    <v-row justify="end">
      <v-spacer></v-spacer>
      <v-col v-show="$vuetify.breakpoint.smAndDown" cols="12" md="3">
        <v-switch
          v-model="mobileBreakpointSwitch"
          :label="$t('ReportWorkshift.mobileBreakpointSwitch_label')"
          hide-details
        ></v-switch> </v-col
      ><v-col cols="12" md="3">
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          :label="$t('ReportWorkshift.selectedHeaders_label')"
          multiple
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text caption"
              >(+{{ selectedHeaders.length - 2 }})</span
            >
          </template>
        </v-select></v-col
      >
    </v-row>
    <v-row class="my-1" align="center">
      <v-col cols="12" md="10">
        <datetime-range-picker filled v-model="dateRange" eager>
        </datetime-range-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="info" large @click="getAll()">
          <v-icon left dark> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col></v-row
    >
    <v-data-table
      :loading="loadingData"
      :headers="showHeaders"
      :items="gridItems"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, 250, -1],
      }"
      class="elevation-1"
      :search="filter.search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      dense
      :mobile-breakpoint="mobileBreakpointTable"
    >
      <template
        v-slot:[`body.prepend`]
        v-if="$vuetify.breakpoint.width > mobileBreakpointTable"
      >
        <tr>
          <td :hidden="actionsHidden"><v-icon>mdi-filter</v-icon></td>
          <td :hidden="idHidden">
            <v-text-field
              v-model="filter.id"
              type="text"
              clearable
              :label="$t('ReportWorkshift.id')"
            ></v-text-field>
          </td>
          <td :hidden="driverIdHidden">
            <v-text-field
              v-model="filter.driverId"
              type="text"
              clearable
              :label="$t('ReportWorkshift.driverId')"
            ></v-text-field>
          </td>
          <td :hidden="driverFirstNameHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.DriverFirstName')"
              v-model="filter.driverFirstName"
              type="text"
              clearable
            ></v-text-field>
          </td>
          <td :hidden="driverLastNameHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.DriverLastName')"
              v-model="filter.driverLastName"
              type="text"
              clearable
            ></v-text-field>
          </td>
          <td :hidden="vehicleLpnHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.VehicleLpn')"
              v-model="filter.vehicleLpn"
              type="text"
              clearable
            ></v-text-field>
          </td>
          <td :hidden="vehicleBrandHidden"></td>
          <td :hidden="vehicleModelHidden"></td>
          <td :hidden="vehicleInternalNameHidden"></td>
          <td :hidden="statusHidden"></td>
          <td :hidden="createdAtHidden"></td>
          <td :hidden="startedAtHidden"></td>
          <td :hidden="finishedAtHidden"></td>
          <td :hidden="orderCounterHidden"></td>
          <td :hidden="breakCounterHidden"></td>
          <td :hidden="breakDurationHidden"></td>
          <td :hidden="mileagesStartHidden"></td>
          <td :hidden="mileagesFinishHidden"></td>
          <td :hidden="mileagesHidden"></td>
          <td :hidden="durationHidden"></td>
          <td :hidden="distanceHidden"></td>
          <td :hidden="netDurationHidden"></td>
          <td :hidden="finalPriceTotalHidden"></td>
          <td :hidden="noteHidden"></td>
        </tr>
      </template>
      <template v-slot:[`body.prepend`] v-else>
        <v-row dense>
          <v-col cols="12" sm="6" :hidden="actionsHidden"></v-col>
          <v-col cols="12" sm="6" :hidden="idHidden">
            <v-text-field
              v-model="filter.id"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportWorkshift.id')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="driverIdHidden">
            <v-text-field
              v-model="filter.driverId"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportWorkshift.driverId')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="driverFirstNameHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.DriverFirstName')"
              v-model="filter.driverFirstName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="driverLastNameHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.DriverLastName')"
              v-model="filter.driverLastName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="vehicleLpnHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.VehicleLpn')"
              v-model="filter.vehicleLpn"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-icon small @click="worklogDetail(item.id)">fa-eye</v-icon>
          <!-- <div @click="worklogDetail(item.id)">{{ item.id }}</div> -->
          <v-icon small @click="worklogOrders(item.id)" class="ml-1"
            >mdi-road-variant</v-icon
          >
        </div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{
          item.createdAt ? $options.filters.formatDateLong(item.createdAt) : "-"
        }}
      </template>

      <template v-slot:[`item.startedAt`]="{ item }">
        {{
          item.startedAt ? $options.filters.formatDateLong(item.startedAt) : "-"
        }}
      </template>

      <template v-slot:[`item.finishedAt`]="{ item }">
        {{
          item.finishedAt
            ? $options.filters.formatDateLong(item.finishedAt)
            : "-"
        }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="WorkStatusColor[item.status]"
          class="white--text"
          label
          small
          :title="$t('workStatus.' + item.status)"
        >
          {{ $t("workStatus." + item.status) }}
        </v-chip>
      </template>
    </v-data-table>
    <v-spacer></v-spacer>
    <br />
    <!--<download-excel
      :data="gridItems"
      worksheet="txm_export"
      name="txm_expor.xls"
      ><v-btn color="info" outlined> Download Data</v-btn>
    </download-excel>-->
    <export-data
      :jsonData="gridItems"
      :jsonFields="jsonFields"
      :outlined="true"
      dense
    >
    </export-data>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
    <v-dialog v-model="worklogDetailDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("ReportWorkshift.worklogDetailDialog_title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6">
              {{ $t("ReportWorkshift.worklogId") }}:
              <strong>{{ worklogDetailItem.id }}</strong>
            </v-col>
            <v-col cols="12" md="6">
              {{ $t("ReportWorkshift.createdAt") }}:
              <strong>{{
                worklogDetailItem.createdAt | formatDateLong
              }}</strong>
            </v-col>
            <v-col cols="12" md="6">
              {{ $t("ReportWorkshift.firstName") }}:
              <strong>{{
                worklogDetailItem.driver
                  ? worklogDetailItem.driver.firstName
                  : ""
              }}</strong>
            </v-col>
            <v-col cols="12" md="6">
              {{ $t("ReportWorkshift.lastName") }}:
              <strong>{{
                worklogDetailItem.driver
                  ? worklogDetailItem.driver.lastName
                  : ""
              }}</strong>
            </v-col>
            <v-col cols="12" md="6">
              {{ $t("ReportWorkshift.breakCounter") }}:
              <strong>{{ worklogDetailItem.breakCounter }}</strong>
            </v-col>
            <v-col cols="12" md="6">
              {{ $t("ReportWorkshift.breakDuration") }}:
              <strong>
                {{
                  worklogDetailItem.breakDuration > 0
                    ? worklogDetailItem.breakDuration + " min."
                    : "-"
                }}</strong
              >
            </v-col>
                        <v-col cols="12" md="12">
              {{ $t("ReportWorkshift.note") }}:
              <strong>
                {{
                  worklogDetailItem.note
                    ? worklogDetailItem.note
                    : ""
                }}</strong
              >
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <h3>{{ $t("ReportWorkshift.worklogDetailDialogItemList_title") }}</h3>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("ReportWorkshift.status") }}</th>
                  <th class="text-left">
                    {{ $t("ReportWorkshift.itemDuration") }}
                  </th>
                  <th class="text-left">
                    {{ $t("ReportWorkshift.createdAt") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in worklogDetailItem.worklogItemList"
                  :key="item.id"
                >
                  <td>
                    <v-chip
                      :color="WorkStatusColor[item.status]"
                      class="white--text"
                      label
                      small
                      :title="$t('workStatus.' + item.status)"
                    >
                      {{ $t("workStatus." + item.status) }}
                    </v-chip>
                  </td>
                  <td>
                    {{
                      item.status == "OFFLINE"
                        ? "-"
                        : item.itemDuration + " min."
                    }}
                  </td>
                  <td>{{ item.createdAt | formatDateLong }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="my-2"></v-divider>
          <h3>
            {{ $t("ReportWorkshift.worklogDetailDialogRequestList_title") }}
          </h3>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("ReportWorkshift.type") }}</th>
                  <th class="text-left">{{ $t("ReportWorkshift.status") }}</th>
                  <th class="text-left">
                    {{ $t("ReportWorkshift.createdAt") }}
                  </th>
                  <th class="text-left">
                    {{ $t("ReportWorkshift.processedAt") }}
                  </th>
                  <!-- <th class="text-left">
                    {{ $t("ReportWorkshift.updatedAt") }}
                  </th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="request in worklogDetailItem.worklogRequestList"
                  :key="request.id"
                >
                  <td>
                    <v-chip
                      :color="WorklogRequestTypeColor[request.type]"
                      class="white--text mr-0"
                      label
                      small
                      :title="$t('worklogRequestType.' + request.type)"
                    >
                      {{ $t("worklogRequestType." + request.type) }}
                    </v-chip>
                  </td>
                  <td>
                    <v-chip
                      :color="WorklogRequestStatusColor[request.status]"
                      class="white--text ml-0"
                      label
                      small
                      :title="$t('worklogRequestStatus.' + request.status)"
                    >
                      {{ $t("worklogRequestStatus." + request.status) }}
                    </v-chip>
                  </td>
                  <td>{{ request.createdAt | formatDateLong }}</td>
                  <td :title="request.processedBy">
                    {{ request.processedAt | formatDateLong }}
                  </td>
                  <!-- <td :title="request.updatedBy">
                    {{ request.updatedAt | formatDateLong }}
                  </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer>
          <v-btn color="primary" text @click="worklogDetailDialog = false">{{
            $t("btnClose")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="worklogOrdersDialog" eager max-width="90vw">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("ReportWorkshift.worklogOrdersDialog_title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <orders-google-map
                :passDataOnMap="passDataOnMap"
                mapHeight="40vh"
                :showBoardMarkers="true"
                :showDestinationMarkers="false"
                :showOrderPath="true"
                :showBoardHeatmap="false"
                :showDestinationHeatmap="false"
              ></orders-google-map
            ></v-col>
          </v-row>
          <v-data-table
            :headers="worklogOrdersHeaders"
            :items="worklogOrdersItems"
            :search="worklogOrdersSearch"
            :loading="worklogOrdersLoading"
            :items-per-page="10"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100, 250, 500, -1],
            }"
          >
            <template v-slot:[`item.id`]="{ item }">
              {{ item.id.substring(0, 8) }}
            </template>

            <template v-slot:[`item.acceptedAt`]="{ item }">
              {{
                item.acceptedAt
                  ? $options.filters.formatDateLong(item.acceptedAt)
                  : "-"
              }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                :color="getStatusColor(item.status)"
                :class="
                  getStatusColorDark(item.status) == true
                    ? 'ml-1 pa-1 white--text'
                    : 'ml-1 pa-1 black--text'
                "
                label
                small
                :title="$t('orderStatus_tooltip.' + item.status)"
              >
                {{ $t("orderStatus." + item.status) }}
              </v-chip>
            </template>

            <template v-slot:[`item.type`]="{ item }">
              <v-chip
                :title="$t('orderType_tooltip.' + item.type)"
                :color="getTypeColor(item.type)"
                dark
                label
                small
              >
                {{ $t("orderType." + item.type) }}
              </v-chip>
            </template>

            <template v-slot:[`item.paymentType`]="{ item }">
              <v-chip
                :title="$t('paymentType_tooltip.' + item.paymentType)"
                :color="getPaymentTypeColor(item.paymentType)"
                dark
                label
                small
              >
                {{ $t("paymentType." + item.paymentType) }}
              </v-chip>
            </template>

            <template v-slot:[`item.source`]="{ item }">
              <v-chip
                :title="$t('source_tooltip.' + item.source)"
                :color="SourceColor[item.source]"
                dark
                label
                small
              >
                {{ $t("source." + item.source) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer>
          <v-btn color="primary" text @click="worklogOrdersDialog = false">{{
            $t("btnClose")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import ExportData from "../components/ExportData.vue";
import {
  Status,
  Type,
  Source,
  PaymentType,
  PaymentTypeColor,
  StatusColor,
  StatusColorDarkContrast,
  TypeColor,
  SourceColor,
} from "../enums/OrderEnum";
import {
  WorkStatusColor,
  WorklogRequestTypeColor,
  WorklogRequestStatusColor,
} from "../enums/WorklogEnum";
import OrdersGoogleMap from "../components/OrdersGoogleMap.vue";

export default {
  components: {
    ExportData,
    OrdersGoogleMap,
  },
  data() {
    return {
      module: moduleDefaultSettings,
      WorkStatusColor,
      WorklogRequestTypeColor,
      WorklogRequestStatusColor,
      Status,
      Type,
      Source,
      PaymentType,
      PaymentTypeColor,
      StatusColor,
      StatusColorDarkContrast,
      TypeColor,
      SourceColor,
      moment: moment,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      //ID taxisluzby a dispecera
      dispatcherId: null,
      taxiserviceId: null,

      //premenne pre vyhladavanie/filter. Podla: https://codepen.io/rveruna/pen/gVBJmq
      filter: {
        search: "",
        id: "",
        driverFirstName: "",
        driverLastName: "",
        driverId: "",
        vehicleLpn: "",
      },

      // gridHeaders: [
      //   {
      //     text: "Id",
      //     align: "start",
      //     sortable: false,
      //     value: "id",
      //   },
      //   { text: this.$t("Status"), value: "status" },
      //   { text: this.$t("Type"), value: "type" },
      //   { text: this.$t("Note"), value: "note" },
      //   {
      //     text: this.$t("CreatedAt"),
      //     value: "createdAt",
      //   },
      // ],
      gridItems: [],
      headers: [],
      selectedHeaders: [],
      headersList: [], //lista stlpcov pre vyber stlpcov
      sortBy: "startedAt",
      sortDesc: true,
      defaultHiddenColumns: [
        "id",
        "driverId",
        "vehicleBrand",
        "vehicleModel",
        "vehicleInternalName",
        "createdAt",
        "distance",
        "infoPriceTotal",
        "note",
      ],
      slotList: [
        "actions",
        "id",
        "driverId",
        "driverFirstName",
        "driverLastName",
        "vehicleLpn",
        "vehicleBrand",
        "vehicleModel",
        "vehicleInternalName",
        "status",
        "createdAt",
        "startedAt",
        "finishedAt",
        "orderCounter",
        "breakCounter",
        "breakDuration",
        "mileagesStart",
        "mileagesFinish",
        "mileages",
        "duration",
        "distance",
        "netDuration",
        "finalPriceTotal",
        "note",
      ],
      //nazvy stlpcov v tabulke, aby sa skryvali filtrovacie polia
      actionsHidden: false,
      idHidden: false,
      driverIdHidden: false,
      driverFirstNameHidden: false,
      driverLastNameHidden: false,
      vehicleLpnHidden: false,
      vehicleBrandHidden: false,
      vehicleModelHidden: false,
      vehicleInternalNameHidden: false,
      statusHidden: false,
      createdAtHidden: false,
      startedAtHidden: false,
      finishedAtHidden: false,
      orderCounterHidden: false,
      breakCounterHidden: false,
      breakDurationHidden: false,
      mileagesStartHidden: false,
      mileagesFinishHidden: false,
      mileagesHidden: false,
      durationHidden: false,
      distanceHidden: false,
      netDurationHidden: false,
      finalPriceTotalHidden: false,
      noteHidden:false,

      //Zoznam stlpcov pre export do excelu
      jsonFields: {
        ID: { field: "id" },
        DRIVER_ID: { field: "driverId" },
        DRIVER_FIRST_NAME: { field: "driverFirstName" },
        DRIVER_LAST_NAME: { field: "driverLastName" },
        VEHICLE_LPN: { field: "vehicleLpn" },
        VEHICLE_BRAND: { field: "vehicleBrand" },
        VEHICLE_MODEL: { field: "vehicleModel" },
        VEHICLE_INTERNAL_NAME: { field: "vehicleInternalName" },
        STATUS: { field: "status" },
        CREATED_AT: { field: "createdAt" },
        STARTED_AT: { field: "startedAt" },
        FINISHED_AT: { field: "finishedAt" },
        ORDER_COUNTER: { field: "orderCounter" },
        BREAK_COUNTER: { field: "breakCounter" },
        BREAK_DURATION: { field: "breakDuration" },
        MILEAGES_START: { field: "mileagesStart" },
        MILEAGES_FINISHED: { field: "mileagesFinish" },
        MILEAGES: { field: "mileages" },
        DURATION: { field: "duration" },
        DISTANCE: { field: "distance" },
        NET_DURATION: { field: "netDuration" },
        FINAL_PRICE_TOTAL: { field: "finalPriceTotal", type: "decimal" },
        NOTE: { field: "note" },
      },

      loadingData: false,
      //DateRangePicker - vyber rozhadu datumov pre zoznam
      dateRange: [], //["2021-07-22","2021-07-24"],

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      //Popup okna
      worklogDetailDialog: false,
      worklogDetailItem: {
        id: null,
        createdAt: null,
        status: null,
        mileagesStart: null,
        mileagesFinish: null,
        mileages: null,
        distance: null,
        startedAt: null,
        finishedAt: null,
        duration: null,
        netDuration: null,
        breakDuration: null,
        breakCounter: null,
        orderCounter: null,
        finalPriceTotal: null,
        itemDuration: 0,
        note: null,
        driver: {
          id: null,
          nickname: null,
          firstName: null,
          lastName: null,
        },
        vehicle: {
          id: null,
          lpn: null,
          brand: null,
          model: null,
        },
        worklogItemList: [],
        worklogRequestList: [],
      },
      worklogOrdersDialog: false,
      worklogOrdersItems: [],
      worklogOrdersHeaders: [
        { text: this.$t("ReportWorkshift.tableHeaders.id"), value: "id" },
        {
          text: this.$t("ReportWorkshift.tableHeaders.status"),
          value: "status",
        },
        { text: this.$t("ReportWorkshift.tableHeaders.type"), value: "type" },
        {
          text: this.$t("ReportWorkshift.tableHeaders.paymentType"),
          value: "paymentType",
        },
        {
          text: this.$t("ReportWorkshift.tableHeaders.source"),
          value: "source",
        },
        {
          text: this.$t("ReportWorkshift.tableHeaders.acceptedAt"),
          value: "acceptedAt",
        },
        {
          text: this.$t("ReportWorkshift.tableHeaders.finalPriceTotal"),
          value: "finalPriceTotal",
        },
        {
          text: this.$t("ReportWorkshift.tableHeaders.driverId"),
          value: "driver.id",
        },
      ],
      worklogOrdersSearch: "",
      worklogOrdersLoading: false,
      passDataOnMap: [],
    };
  },

  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
    var headersMap = this.headersMap;
    this.headers = Object.values(headersMap);
    //Potrebujeme sa zbavit hodnoty "divide" v headersList. Ale upravu som nepozil vo v-select, musel som hodnotu odstranit z headerMap. Doplnala do v-select oddelovace miesto textu. Mozno sa este zamyslim co s tym urobit.
    //divide bolo treba za ucelom formatovania v tabulke, aby boli oddlene stlpce ciarami
    /*headersMap.forEach((header) => {
      this.headersList.push({
        text: header.text,
        value: header.value,
      });
    });*/
    //this.headersList = Object.values(headersMap);

    this.selectedHeaders = this.headers;

    //Zistenie zoznamu skrytych stlpcov z Cookies
    var tempHiddenColumns = this.getCookie("actualHiddenColumnsReportWorkshift")
      ? JSON.parse(this.getCookie("actualHiddenColumnsReportWorkshift"))
      : [];
    //ak existuje cookie, nacitat ulozenu hodnotu (zoznam stlpcov, ktore sa maju skryt)
    if (tempHiddenColumns.length > 0) {
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !tempHiddenColumns.includes(el.value)
      );
    } else {
      //ak neexistuje cookie, nastavit defaultne hodnoty
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !this.defaultHiddenColumns.includes(el.value)
      );
    }
  },

  mounted() {
    //ID prihlaseneho dispecera
    this.dispatcherId = this.$store.state.user.id;
    //console.log("dispatcherId", dispatcherId);
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    //console.log("mounted - this.dateRange", this.dateRange);

    var mobileBreakpointSwitch = this.getCookie("mobileBreakpointSwitch");
    if (mobileBreakpointSwitch && mobileBreakpointSwitch != null) {
      this.mobileBreakpointSwitch =
        mobileBreakpointSwitch.toLowerCase() == "true";
    } else {
      this.mobileBreakpointSwitch = false;
    }

    //Niekedy pri prvom nacitany stranky, nie je hned vyrenderovany sub component, tak pocat 2s. Inak to neviem zatial vyriesit.
    if (this.dateRange[0] == undefined) {
      setTimeout(() => {
        this.getAll();
      }, 2000);
    } else {
      this.getAll();
    }
  },

  watch: {
    showHeaders: function () {
      //ak sa zobrazi hlavicka stlpca, zobrazit aj filter
      var tempShowHearders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          tempShowHearders.some(function (e) {
            return e.value == item;
          })
        ) {
          this[item + "Hidden"] = false;
        } else {
          this[item + "Hidden"] = true;
        }
      });
    },
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },

  computed: {
    showHeaders() {
      //console.log("computed - this.selectedHeaders", this.selectedHeaders);
      //ak sa zobrazi hlavicka stlpca, zobrazit aj filter
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },

    headersMap() {
      return [
        {
          text: this.$t("OrderListView.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: "ID",
          //value: "driver.id",
          value: "id",
          filter: (value) => {
            if (!this.filter.id) return true;
            if (value) return value === parseInt(this.filter.id);
          },
        },
        {
          text: this.$t("OrderListView.DriverId"),
          //value: "driver.id",
          value: "driverId",
          filter: (value) => {
            if (!this.filter.driverId) return true;
            if (value) return value === parseInt(this.filter.driverId);
          },
        },
        {
          text: this.$t("OrderListView.DriverFirstName"),
          align: "start",
          sortable: true,
          value: "driverFirstName",
          filter: (value) => {
            if (!this.filter.driverFirstName) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.driverFirstName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.DriverLastName"),
          align: "start",
          sortable: true,
          value: "driverLastName",
          filter: (value) => {
            if (!this.filter.driverLastName) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.driverLastName.toLowerCase());
            }
          },
        },

        {
          text: this.$t("OrderListView.VehicleLpn"),
          align: "start",
          sortable: true,
          value: "vehicleLpn",
          filter: (value) => {
            if (!this.filter.vehicleLpn) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.vehicleLpn.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.VehicleBrand"),
          value: "vehicleBrand",
        },
        {
          text: this.$t("OrderListView.VehicleModel"),
          value: "vehicleModel",
        },
        {
          text: this.$t("OrderListView.VehicleInternalName"),
          value: "vehicleInternalName",
        },
        {
          text: this.$t("OrderListView.Status"),
          value: "status",
        },
        {
          text: this.$t("OrderListView.CreatedAt"),
          value: "createdAt",
        },
        {
          text: this.$t("OrderListView.StartedAt"),
          value: "startedAt",
        },
        {
          text: this.$t("OrderListView.FinishedAt"),
          value: "finishedAt",
        },
        {
          text: this.$t("OrderListView.OrderCounter"),
          value: "orderCounter",
        },
        {
          text: this.$t("OrderListView.BreakCounter"),
          value: "breakCounter",
        },
        {
          text: this.$t("OrderListView.BreakDuration"),
          value: "breakDuration",
        },
        {
          text: this.$t("OrderListView.MileagesStart"),
          value: "mileagesStart",
        },
        {
          text: this.$t("OrderListView.MileagesFinish"),
          value: "mileagesFinish",
        },
        {
          text: this.$t("OrderListView.Mileages"),
          value: "mileages",
        },
        {
          text: this.$t("OrderListView.Duration"),
          value: "duration",
        },
        {
          text: this.$t("OrderListView.Distance"),
          value: "distance",
        },
        {
          text: this.$t("OrderListView.NetDuration"),
          value: "netDuration",
        },
        {
          text: this.$t("OrderListView.FinalPriceTotal"),
          value: "finalPriceTotal",
        },
        {
          text: this.$t("OrderListView.Note"),
          value: "note",
        },
      ];
    },
  },

  methods: {
    getAll() {
      //Ak nie je povoleny modul s reportmi, nenaciata ziadne data
      if (this.module.report == false) {
        this.gridItems = [];
        //this.$router.push({ name: "dashboard" });
        //snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgModuleReportNotEnabled");
        this.snackbar.color = "error";
        return;
      }
      //console.log("getAll - dateRange ", this.dateRange);
      //Ulozit si nastavenie rozsahu datumov do cookies
      //this.setCookie("dateRange", JSON.stringify(this.dateRange), 1);
      //console.log("getAll - setCookie - dateRange",JSON.stringify(this.dateRange));
      this.setHiddenColumnListCookie();
      if (this.checkDateRange() == false) {
        return;
      }
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/report/worklog?from=${this.dateRange[0]}&to=${this.dateRange[1]}`
        )
        .then((response) => {
          //console.log("response", response);
          this.loadingData = false;
          this.gridItems = response.data;
          //remove duplicates from array by id
          /*this.gridItems = this.gridItems.filter(
            (thing, index, self) =>
              index ===
              self.findIndex((t) => t.id === thing.id && t.id !== undefined)
          );*/
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    checkDateRange() {
      //dateRange moze byt iba v rozsahu 2 mesiacov
      if (this.dateRange[0] == null || this.dateRange[1] == null) {
        alert("Zadajte rozsah dátumov");
        return false;
      } else {
        var dateFrom = new Date(this.dateRange[0]);
        var dateTo = new Date(this.dateRange[1]);
        var diffTime = Math.abs(dateTo - dateFrom);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        //console.log("diffDays", diffDays);
        if (diffDays > 63) {
          alert("Rozsah dátumov môže byť maximálne 2 mesiace");
          return false;
        } else {
          return true;
        }
      }
    },
    setHiddenColumnListCookie() {
      //aktualny zoznam poli, ktore sa nemaju zobrazit, ulozit do cookies
      var actualHiddenColumns = [];
      //compare slotList and showHeaders
      var tempShowHeaders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          !tempShowHeaders.some(function (e) {
            return e.value == item;
          })
        ) {
          actualHiddenColumns.push(item);
        }
      });
      this.setCookie(
        "actualHiddenColumnsReportWorkshift",
        JSON.stringify(actualHiddenColumns),
        30
      );
    },
    getStatusColor(status) {
      return this.StatusColor[status];
    },
    getTypeColor(type) {
      return this.TypeColor[type];
    },
    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //Vymazanie cookie
    eraseCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },

    //Konverzia mesiaca na nazov
    toMonthName(monthNumber) {
      //console.log("toMonthName: " + monthNumber);
      if (isNaN(monthNumber)) {
        //Ak nie je cislo, konvertova na cislo
        monthNumber = parseInt(monthNumber);
      }
      var localeLanguage = this.$store.state.appLocale;
      if (localeLanguage == "en" || localeLanguage == "us") {
        localeLanguage = "en-US";
      } else if (localeLanguage == "cs" || localeLanguage == "cz") {
        localeLanguage = "cs-CZ";
      } else if (localeLanguage == "sk") {
        localeLanguage = "sk-SK";
      } else {
        localeLanguage = "en-US";
      }
      //console.log("localeLanguage", localeLanguage);
      const date = new Date();
      date.setMonth(monthNumber - 1);
      //console.log("date", date);
      return date.toLocaleString(localeLanguage, {
        month: "long",
      });
    },
    worklogDetail(id) {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/driver/worklog/${id}`
        )
        .then((response) => {
          //console.log("response", response.data);
          var temp = response.data;
          //Usporiadať worklogItemList a worklogRequestList podľa dátumu vytvorenia
          if (temp.worklogItemList != null) {
            temp.worklogItemList.sort(function (a, b) {
              return new Date(a.createdAt) - new Date(b.createdAt);
            });
            //itemDuration v minutach oproti nasledujúcemu záznamu
            temp.worklogItemList.forEach((item, index) => {
              if (index < temp.worklogItemList.length - 1) {
                item.itemDuration = Math.ceil(
                  (new Date(temp.worklogItemList[index + 1].createdAt) -
                    new Date(item.createdAt)) /
                    60000
                );
              } else {
                item.itemDuration = 0;
              }
            });
          }
          if (temp.worklogRequestList != null) {
            temp.worklogRequestList.sort(function (a, b) {
              return new Date(a.createdAt) - new Date(b.createdAt);
            });
          }
          //console.log("temp", temp);
          this.worklogDetailDialog = true;
          this.worklogDetailItem = temp;
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    worklogOrders(id) {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/report/worklog-order/${id}`
        )
        .then((response) => {
          //console.log("response", response.data);
          var temp = response.data;
          this.worklogOrdersDialog = true;
          this.worklogOrdersItems = temp;
          this.passDataOnMap = temp;
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    getStatusColor(status) {
      return this.StatusColor[status];
    },
    getStatusColorDark(status) {
      if (status == undefined) {
        return this.StatusColorDarkContrast["NEW"];
      } else {
        return this.StatusColorDarkContrast[status];
      }
    },
    getTypeColor(type) {
      return this.TypeColor[type];
    },
    getPaymentTypeColor(type) {
      return this.PaymentTypeColor[type];
    },
  },
};
</script>
