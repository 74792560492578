export const Status = Object.freeze({
  DISABLED: "DISABLED", // Neaktívne, vyradené z prevádzky taxislužby (0)
  ACTIVE: "ACTIVE", // Aktívne, v prevádzke taxislužby (1)
  BLOCKED: "BLOCKED", // Blokovaný, dočasne zablokované (2)
  ARCHIVED: "ARCHIVED", // Archivovaný (3)
});

export const Type = Object.freeze({
  SEDAN: "SEDAN", // Sedan (1)
  COMBI: "COMBI", // Kombi (2)
  CABRIO: "CABRIO", // Kabriolet (3)
  COUPE: "COUPE", // Coupé (4)
  HATCHBACK: "HATCHBACK", // Hatchback (5)
  VAN: "VAN", // Osobná dodávka (van) (6)
  MICROBUS: "MICROBUS", // Mikrobus (7)
  BUS: "BUS", // Autobus (8)
  VAN_CARGO: "VAN_CARGO", // Nákladná dodávka (9)
  PICKUP: "PICKUP", // Pickup (10)
  SUV: "SUV", // SUV (11)
});

//@JsonFormat(shape = JsonFormat.Shape.)
export const Color = Object.freeze({
  WHITE: "WHITE", // biela, color_id :#FFFFFF
  BLACK: "BLACK", // Čierna, color_id: #000000
  SILVER: "SILVER", // Strieborná, color_id: #C0C0C0
  GRAY: "GRAY", // Šedá, color_id: #696969
  BROWN: "BROWN", // Hnedá, color_id: #996633
  PURPLE: "PURPLE", // Purpurová, color_id: #800080
  VIOLET: "VIOLET", // Fialová, color_id: #EE82EE
  BLUE: "BLUE", // Modrá, color_id: #0000FF
  DARKBLUE: "DARKBLUE", // Tmavomodrá, color_id: #00008B
  LIGHTBLUE: "LIGHTBLUE", // Svetlomodrá, color_id: #ADD8E6
  SKYBLUE: "SKYBLUE", // Strednemodrá, color_id: #87CEEB
  RED: "RED", // Červená, color_id: #FF0000
  ORANGE: "ORANGE", //  Oranžová, color_id: #ffa500
  DARKORANGE: "DARKORANGE", // Tmavooranžová, color_id: #FF8C00
  YELLOW: "YELLOW", // Žltá, color_id: #FFFF00
  GREEN: "GREEN", // Zelená, color_id: #00FF00
  DARKGREEN: "DARKGREEN", // Tmavozelená, color_id: #006400
  LIGHTGREEN: "LIGHTGREEN", // Svetlozelená, color_id: #90EE90
  PINK: "PINK", // Ružová, color_id: #FF99CC
  BEIGE: "BEIGE", // Béžová, color_id: #F5F5DC
  GOLD: "GOLD", // Zlatá, color_id: #FFD700
});

export const ColorRgb = Object.freeze({
  WHITE: "#FCFCFC", // biela, color_id :#FFFFFF
  BLACK: "#000000", // Čierna, color_id: #000000
  SILVER: "#C0C0C0", // Strieborná, color_id: #C0C0C0
  GRAY: "#696969", // Šedá, color_id: #696969
  BROWN: "#996633", // Hnedá, color_id: #996633
  PURPLE: "#800080", // Purpurová, color_id: #800080
  VIOLET: "#EE82EE", // Fialová, color_id: #EE82EE
  BLUE: "#0000FF", // Modrá, color_id: #0000FF
  DARKBLUE: "#00008B", // Tmavomodrá, color_id: #00008B
  LIGHTBLUE: "#ADD8E6", // Svetlomodrá, color_id: #ADD8E6
  SKYBLUE: "#87CEEB", // Strednemodrá, color_id: #87CEEB
  RED: "#FF0000", // Červená, color_id: #FF0000
  ORANGE: "#ffa500", //  Oranžová, color_id: #ffa500
  DARKORANGE: "#FF8C00", // Tmavooranžová, color_id: #FF8C00
  YELLOW: "#FFFF00", // Žltá, color_id: #FFFF00
  GREEN: "#00FF00", // Zelená, color_id: #00FF00
  DARKGREEN: "#006400", // Tmavozelená, color_id: #006400
  LIGHTGREEN: "#90EE90", // Svetlozelená, color_id: #90EE90
  PINK: "#FF99CC", // Ružová, color_id: #FF99CC
  BEIGE: "#F5F5DC", // Béžová, color_id: #F5F5DC
  GOLD: "#FFD700", // Zlatá, color_id: #FFD700
});

export const ColorDarkContrast = Object.freeze({
  WHITE: false, // biela, color_id :#FFFFFF
  BLACK: true, // Čierna, color_id: #000000
  SILVER: false, // Strieborná, color_id: #C0C0C0
  GRAY: true, // Šedá, color_id: #696969
  BROWN: true, // Hnedá, color_id: #996633
  PURPLE: true, // Purpurová, color_id: #800080
  VIOLET: false, // Fialová, color_id: #EE82EE
  BLUE: true, // Modrá, color_id: #0000FF
  DARKBLUE: true, // Tmavomodrá, color_id: #00008B
  LIGHTBLUE: false, // Svetlomodrá, color_id: #ADD8E6
  SKYBLUE: false, // Strednemodrá, color_id: #87CEEB
  RED: true, // Červená, color_id: #FF0000
  ORANGE: false, //  Oranžová, color_id: #ffa500
  DARKORANGE: true, // Tmavooranžová, color_id: #FF8C00
  YELLOW: false, // Žltá, color_id: #FFFF00
  GREEN: false, // Zelená, color_id: #00FF00
  DARKGREEN: true, // Tmavozelená, color_id: #006400
  LIGHTGREEN: false, // Svetlozelená, color_id: #90EE90
  PINK: false, // Ružová, color_id: #FF99CC
  BEIGE: false, // Béžová, color_id: #F5F5DC
  GOLD: false, // Zlatá, color_id: #FFD700
});



export const Eco = Object.freeze({
  DIESEL: "DIESEL", // Nafta / Diesel (1)
  GASOLINE: "GASOLINE", // Benzín / Gasoline (2)
  HYBRID: "HYBRID", // Hybrid Electric (3)
  PLUGIN: "PLUGIN", // Plug-in Hybrid Electric (4)
  CNG: "CNG", // CNG (5)
  LPG: "LPG", // LPG (6)
  ELECTRIC: "ELECTRIC", // Elektrické / Electric (7)
  HYDROGEN: "HYDROGEN", // Vodík/Hydrogen (8)
});

export const Smoking = Object.freeze({
  YES: "YES",
  NO: "NO",
});

export const Airconditioning = Object.freeze({
  YES: "YES",
  NO: "NO",
});

export const ChildrenChair = Object.freeze({
  NONE: "NONE", // Bez detskej sedačky (0)
  BOOSTER: "BOOSTER", // Podsedák/Booster Seat (1)
  SEAT: "SEAT", // Detská sedačka/Children Car Sea (2)
  ALL: "ALL",
});

export const SpecialTransport = Object.freeze({
  NONE: "NONE",
  OLD: "OLD", // preprava starších a imobilných ľudí (1)
  CHAIR: "CHAIR", // preprava ľudí na vozíku (2)
  ALL: "ALL",
});

export const Animal = Object.freeze({
  NONE: "NONE",
  SMALL: "SMALL", // Malé do 10kg / Any animals up to 10kg (not dangerous) (1)
  MEDIUM: "MEDIUM", // Stredné do 20kg / Any animals up to 20kg (2)
  BIG: "BIG", // Veľké do 50kg / Any animals up to 50kg (3)
});

export const Autonomous = Object.freeze({
  L0: "L0", // Bez autonómnych funkcií (0)
  L1: "L1", // Úroveň 1 - Adaptívny tempomat (1)
  L2: "L2", // Úroveň 2 - Udržiavanie jazdného pruhu (2)
  L3: "L3", // Úroveň 3 - Čiastočné autonómne riadenie (3)
  L4: "L4", // Úroveň 4 - Takmer autonómne riadenie (4)
  L5: "L5", // Úroveň 5 - Riadenie plne bez zásahu vodiča (5)
});

export const Luggage = Object.freeze({
  //BAGGAGE: "BAGGAGE", // Veľká batožina (12)
  //BIG_BAGGAGE: "BIG_BAGGAGE", // Veľmi veľká batožina (13)
  SMALL: "SMALL", //Malá batožina
  MEDIUM: "MEDIUM", // Stredná batožina (12)
  BIG: "BIG", // Veľká batožina (13)
});

export const paymentTerminal = Object.freeze({
  YES: "YES", // Dostupný terminál
  NO: "NO", // Bez terminálu 
});

export const VehicleBrand = Object.freeze({
  APPLE: "APPLE", //  Apple
  ACURA: "ACURA", //  Acura
  ABARTH: "ABARTH", //  Abarth
  ALPHA_ROMEO: "ALPHA_ROMEO", //  Alfa Romeo
  ALPINA: "ALPINA", //  Alpina
  ASTON_MARTIN: "ASTON_MARTIN", //  Aston Martin
  AUDI: "AUDI", //  Audi
  AUSTIN: "AUSTIN", //  Austin
  BENTLEY: "BENTLEY", //  Bentley
  BMW: "BMW", //  BMW
  BUGATTI: "BUGATTI", //  Bugatti
  BUICK: "BUICK", //  Buick
  CADILLAC: "CADILLAC", //  Cadillac
  CHEVROLET: "CHEVROLET", //  Chevrolet
  CHRYSLER: "CHRYSLER", //  Chrysler
  CITROEN: "CITROEN", //  Citroen
  DACIA: "DACIA", //  Dacia
  DAEWOO: "DAEWOO", //  Daewoo
  DODGE: "DODGE", //  Dodge
  DS: "DS", //  DS
  FIAT: "FIAT", //  Fiat
  FORD: "FORD", //  Ford
  GENESIS: "GENESIS", //  Genesis
  GMC: "GMC", // GMC
  HONDA: "HONDA", //  Honda
  HUMMER: "HUMMER", //  Hummer
  HYUNDAI: "HYUNDAI", //  Hyundai
  INFINITY: "INFINITY", //  Infiniti
  IVECO: "IVECO", //  Iveco
  JAGUAR: "JAGUAR", //  Jaguar
  JEEP: "JEEP", //  Jeep
  KIA: "KIA", //  Kia
  LANCIA: "LANCIA", //  Lancia
  LAND_ROVER: "LAND_ROVER", //  Land Rover
  LINCOLN: "LINCOLN", //  Lincoln
  LEXUS: "LEXUS", //  Lexus
  LOTUS: "LOTUS", //  Lotus
  MASERATI: "MASERATI", //  Maserati
  MAZDA: "MAZDA", //  Mazda
  MERCEDES_BENZ: "MERCEDES_BENZ", //  Mercedes-Benz
  MINI: "MINI", //  MINI
  MITSUBISHI: "MITSUBISHI", //  Mitsubishi
  NIKOLA: "NIKOLA", //  Nikola
  NISSAN: "NISSAN", //  Nissan
  OPEL: "OPEL", //  Opel
  PEUGEOT: "PEUGEOT", //  Peugeot
  PORSCHE: "PORSCHE", //  Porsche
  RENAULT: "RENAULT", //  Renault
  RIVIAN: "RIVIAN", //  Rivian
  ROLLS_ROYCE: "ROLLS_ROYCE", //  Rolls-Royce
  SAAB: "SAAB", //  Saab
  SEAT: "SEAT", //  Seat
  SKODA: "SKODA", //  Skoda
  SMART: "SMART", //  Smart
  SONY: "SONY", //  Sony
  SSANG_YONG: "SSANG_YONG", //  Ssang Yong
  SUBARU: "SUBARU", //  Subaru
  SUZUKI: "SUZUKI", //  Suzuki
  TATA: "TATA", //  Tata
  TESLA: "TESLA", //  Tesla
  TOYOTA: "TOYOTA", //  Toyota
  VENTURI: "VENTURI", //  Venturi
  VOLKSWAGEN: "VOLKSWAGEN", //  Volkswagen
  VOLVO: "VOLVO", //  Volvo
  OTHER: "OTHER", //  Iné
});
