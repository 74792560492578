export const WorkStatusColor = Object.freeze({
  OFFLINE: "red",
  ONLINE: "green ",
  ON_BREAK: "orange",
  NONE: "black",
  UNDEFINED: "black",
});

export const WorklogRequestTypeColor = Object.freeze({
  OFFLINE: "red",
  ONLINE: "green ",
  ON_BREAK: "orange",
  NONE: "black",
  UNDEFINED: "black",
});

export const WorklogRequestStatusColor = Object.freeze({
  NEW: "orange",
  CONFIRMED: "green",
  DECLINED: "red",
  DONE: "blue",
  CANCELLED: "pink",
  UNDEFINED: "black",
});
