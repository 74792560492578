<template>
  <div>
    <div id="map" ref="map" :style="{ height: mapHeight }"></div>
    <div>
      <v-row justify="start" class="mt-2">
        <v-switch
          v-model="enableBoardMarkers"
          :label="$t('OrdersGoogleMap.enableBoardMarkers')"
          class="mr-1"
          x-small
          @click="displayItemsOnMap()"
        ></v-switch>
        <v-switch
          v-model="enableDestinationMarkers"
          :label="$t('OrdersGoogleMap.enableDestinationMarkers')"
          class="mr-1"
          x-small
          @click="displayItemsOnMap()"
        ></v-switch>
        <v-switch
          v-model="enableOrderPath"
          :label="$t('OrdersGoogleMap.enableOrderPath')"
          class="mr-1"
          x-small
          @click="displayItemsOnMap()"
        ></v-switch>
        <v-switch
          v-model="enableBoardHeatmap"
          :label="$t('OrdersGoogleMap.enableBoardHeatmap')"
          class="mr-1"
          x-small
          @click="displayItemsOnMap()"
        ></v-switch>
        <v-switch
          v-model="enableDestinationHeatmap"
          :label="$t('OrdersGoogleMap.enableDestinationHeatmap')"
          class="mr-1"
          x-small
          @click="displayItemsOnMap()"
        ></v-switch>
      </v-row>
      <v-row justify="start" class="my-2">
        {{ $t("OrdersGoogleMap.heatmapSettings") }}: &nbsp;
        <v-btn @click="changeOpacity()" class="mr-1" small>
          {{ $t("OrdersGoogleMap.heatmapOpacity") }}
        </v-btn>
        <v-btn @click="changeRadius()" class="mr-1" small>
          {{ $t("OrdersGoogleMap.heatmapRadius") }}
        </v-btn>
        <v-btn @click="changeBoardGradient()" class="mr-1" small>
          {{ $t("OrdersGoogleMap.heatmapBoardGradient") }}
        </v-btn>
        <v-btn @click="changeDestinationGradient()" class="mr-1" small>
          {{ $t("OrdersGoogleMap.heatmapDestinationGradient") }}
        </v-btn>
      </v-row>
    </div>
  </div>
</template>
<style scoped>
/* #map {
  height: 40vh;
} */
</style>
<script>
import axios from "axios";
export default {
  components: {
    //  Prices,
  },
  name: "OrdersGoogleMap",
  props: {
    passDataOnMap: {
      type: Array,
      default: [],
    },
    mapHeight: {
      type: String,
      default: "40vh",
    },
    showBoardMarkers: {
      type: Boolean,
      default: false,
    },
    showDestinationMarkers: {
      type: Boolean,
      default: false,
    },
    showOrderPath: {
      type: Boolean,
      default: false,
    },
    showBoardHeatmap: {
      type: Boolean,
      default: false,
    },
    showDestinationHeatmap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: null,
      //Defaultne centrum zobrazenia stredu vsetkych map
      mapCenter: { lat: 48.148, lng: 18.127 },
      map: null,
      //Zoznam vygenerovanych objektov na mape
      enableBoardMarkers: false,
      enableDestinationMarkers: false,
      enableOrderPath: false,
      enableBoardHeatmap: false,
      enableDestinationHeatmap: false,
      //Premenne pre zobrazenie objektov na mape
      allModifiedData: [],
      boardMarkers: [],
      destinationMarkers: [],
      orderPath: [],
      heatmapBoardLayer: null,
      heatmapDestinationLayer: null,
      //Nastavenie heatmapy
      heatmapRadius: 30,
      heatmapOpacity: null,
      heatmapGradientBlue: [
        "rgba(0, 255, 255, 0)",
        "rgba(0, 255, 255, 1)",
        "rgba(0, 191, 255, 1)",
        "rgba(0, 127, 255, 1)",
        "rgba(0, 63, 255, 1)",
        "rgba(0, 0, 255, 1)",
        "rgba(0, 0, 223, 1)",
        "rgba(0, 0, 191, 1)",
        "rgba(0, 0, 159, 1)",
        "rgba(0, 0, 127, 1)",
        "rgba(63, 0, 91, 1)",
        "rgba(127, 0, 63, 1)",
        "rgba(191, 0, 31, 1)",
        "rgba(255, 0, 0, 1)",
      ],
      heatmapBoardGradient: null,
      heatmapDestinationGradient: null,
      //bounds pre mapu
      mapBounds: [],
      //Markery
      markerImageSet: [],
    };
  },
  watch: {
    passDataOnMap: function () {
      //console.log(this.passDataOnMap);
      /*if (
        document.querySelector(
          "script[src*='https://maps.googleapis.com/maps/api/js']"
        )
      ) {
        console.log(
          "ShapesGoogleMap.vue - passDataOnMap - Google Maps API already loaded"
        );*/
      var tempData = this.passDataOnMap;
      //Doplnenie adresy vyzdvihnutia a ciela do zakladneho zoznamu, lebo inak je o uroven nizsie a neda sa to v tabulke zobrazit
      var tempModifiedData = [];
      tempData.forEach(function (item) {
        if (item.waypointList) {
          //console.log("item.waypointList", item.waypointList);
          item.waypointList.forEach(function (waypointValue) {
            //console.log("waypointValue", waypointValue);
            if (
              waypointValue.type == "BOARD" &&
              waypointValue.status != "DISABLED"
            ) {
              item.boardAddress = waypointValue.address;
              item.boardLatitude = waypointValue.latitude;
              item.boardLongitude = waypointValue.longitude;
            } else if (
              waypointValue.type == "DESTINATION" &&
              waypointValue.status != "DISABLED"
            ) {
              item.destinationAddress = waypointValue.address;
              item.destinationLatitude = waypointValue.latitude;
              item.destinationLongitude = waypointValue.longitude;
            }
          });
        }
        tempModifiedData.push(item);
      });
      this.allModifiedData = tempModifiedData;
      this.displayItemsOnMap();
      //}
    },
  },
  //computed:{
  //formatDate() {
  //return moment(this.item.validFrom).local();
  //}
  //},
  created() {
    this.generateMarkers();
  },
  mounted() {
    this.enableBoardMarkers = this.showBoardMarkers;
    this.enableDestinationMarkers = this.showDestinationMarkers;
    this.enableOrderPath = this.showOrderPath;
    this.enableBoardHeatmap = this.showBoardHeatmap;
    this.enableDestinationHeatmap = this.showDestinationHeatmap;
    this.heatmapDestinationGradient = this.heatmapGradientBlue;
    this.initMap();
  },
  methods: {
    //OSM mapa https://codepen.io/ItsMeStevieG/pen/QRgjmL
    initMap() {
      //console.log("function: initMap");
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: this.mapCenter, //the center of the map
        zoom: 6,
        maxZoom: 16,
        minZoom: 4,
        streetViewControl: false,
        fullscreenControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP, // typ mapy s cestami
        disableDefaultUI: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_CENTER, //umiestnenie prepínača typu mapy - dole v strede
          mapTypeIds: [
            google.maps.MapTypeId.ROADMAP,
            google.maps.MapTypeId.HYBRID,
          ],
        },
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_CENTER, //BOTTOM_LEFT
        },
        scaleControl: false,
      });
    },
    displayItemsOnMap() {
      var itemList = this.allModifiedData;
      var map = this.map;
      var bounds = new google.maps.LatLngBounds();
      var colorName = eval(0); //Reset poradia farby
      var indexMarker = 0;
      var markerImageSet = this.markerImageSet;
      //console.log(markerImageSet);
      //console.log(itemList);
      var boardMarkers = this.boardMarkers;
      //Premazanie starých board markerov
      for (var i = 0; i < boardMarkers.length; i++) {
        boardMarkers[i].setMap(null);
      }
      boardMarkers = [];
      this.boardMarkers = [];

      var destinationMarkers = this.destinationMarkers;
      //Premazanie starých destination markerov
      for (var i = 0; i < destinationMarkers.length; i++) {
        destinationMarkers[i].setMap(null);
      }
      destinationMarkers = [];
      this.destinationMarkers = [];

      var orderPath = this.orderPath;
      //Premazanie starých path
      for (var i = 0; i < orderPath.length; i++) {
        orderPath[i].setMap(null);
      }
      orderPath = [];
      this.orderPath = [];
      //vymazanie boardHeatmap
      var heatmapBoardLayer = this.heatmapBoardLayer;
      var heatmapBoardLayerArray = [];
      if (heatmapBoardLayer) {
        heatmapBoardLayer.setMap(null);
        heatmapBoardLayer = null;
        this.heatmapBoardLayer = null;
      }
      //vymazanie destinationHeatmap
      var heatmapDestinationLayer = this.heatmapDestinationLayer;
      var heatmapDestinationLayerArray = [];
      if (heatmapDestinationLayer) {
        heatmapDestinationLayer.setMap(null);
        heatmapDestinationLayer = null;
        this.heatmapDestinationLayer = null;
      }

      //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
      if (itemList.constructor.name != "Array") {
        var newItemList = [];
        newItemList.push(itemList);
        itemList = newItemList;
      }
      //ak neobsahuej acceptedAt, tak do pola acceptedAt pridame hodnotu z pola requestedAt alebo createdAt
      for (var orderItem in itemList) {
        var item = itemList[orderItem];
        if (!item.acceptedAt) {
          if (item.requestedAt) {
            item.acceptedAt = item.requestedAt;
          } else if (item.createdAt) {
            item.acceptedAt = item.createdAt;
          }
        }
      }
      //Usporiadat podla datumu acceptedAt
      itemList.sort(function (a, b) {
        return new Date(a.acceptedAt) - new Date(b.acceptedAt);
      });

      //Vytvorenie nových objektov na zobrazenie na mape
      for (var orderItem in itemList) {
        //console.log("orderItem", orderItem);
        var item = itemList[orderItem];
        colorName = colorName + eval(1);
        if (colorName > 80) {
          colorName = 1;
        }
        indexMarker = indexMarker + eval(1);
        /*console.log("colorName", colorName);
        console.log("id", item.id);
        console.log("lat", item.boardLatitude);
        console.log("lng", item.boardLongitude);
        console.log("status", item.status);
        console.log("acceptedAt", item.acceptedAt);*/
        //Na zaklade stavu objednavky definovat markerImageType ako CREATED, FINISHED, CANCELLED
        var markerImageType = "DEFAULT";
        if (
          item.status == "PREREGISTERED" ||
          item.status == "TRANSFERED" ||
          item.status == "NEW" ||
          item.status == "PLANNED" ||
          item.status == "PENDING"
        ) {
          markerImageType = "OPEN";
        } else if (
          item.status == "ACCEPTED" ||
          item.status == "WAITING" ||
          item.status == "IN_PROGRESS"
        ) {
          markerImageType = "PROGRESS";
        } else if (
          item.status == "FINISHED" ||
          item.status == "CHARGED" ||
          item.status == "SETTLED"
        ) {
          markerImageType = "FINISHED";
        } else if (
          item.status == "ARCHIVED" ||
          item.status == "CANCELLED_PREREGISTERED" ||
          item.status == "CANCELLED_NEW" ||
          item.status == "CANCELLED_PLANNED" ||
          item.status == "CANCELLED_PENDING" ||
          item.status == "CANCELLED_ACCEPTED" ||
          item.status == "CANCELLED_WAITING" ||
          item.status == "CANCELLED_IN_PROGRESS" ||
          item.status == "CANCELLED_FINISHED" ||
          item.status == "CANCELLED_CHARGED" ||
          item.status == "CANCELLED_SETTLED"
        ) {
          markerImageType = "CANCELLED";
        }

        //BOARD MARKER
        if (this.enableBoardMarkers) {
          var markerBoardImage = {
            url: markerImageSet["BOARD" + markerImageType],
            size: new google.maps.Size(31, 44),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(16, 44),
            labelOrigin: new google.maps.Point(15, 15),
          };
          //console.log(markerBoardImage);
          // create marker
          var boardMarker = new google.maps.Marker({
            map: map,
            icon: markerBoardImage,
            shape: {
              coords: [1, 15, 5, 5, 16, 1, 24, 5, 31, 15, 16, 44],
              type: "poly",
            },
            position: {
              lat: item.boardLatitude,
              lng: item.boardLongitude,
            },
            title:
              "ID: " +
              item.id +
              "\nStatus: " +
              item.status +
              "\nUTC: " +
              item.acceptedAt +
              "\nA: => " +
              item.boardAddress +
              "\nB: " +
              item.destinationAddress,
            //icon: {
            //url: "/img/map-marker.png",
            //scaledSize: new google.maps.Size(40, 40),
            //},
            //colorName je poradove cislo podla datumu, rovno pouzite ako label
            label: {
              text: "" + indexMarker + "",
              color: "#000",
              fontSize: "90%",
              fontWeight: "normal",
            },
          });
          // add to array
          boardMarkers.push(boardMarker);
          // add to map
          boardMarker.setMap(map);
          // add to bounds
          bounds.extend(boardMarker.position);
        }
        //DESTINATION MARKER
        if (this.enableDestinationMarkers) {
          var markerDestinationImage = {
            url: markerImageSet["DESTINATION" + markerImageType],
            size: new google.maps.Size(31, 44),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(16, 44),
            labelOrigin: new google.maps.Point(15, 15),
          };
          // create marker
          var destinationMarker = new google.maps.Marker({
            map: map,
            icon: markerDestinationImage,
            shape: {
              coords: [1, 15, 5, 5, 16, 1, 24, 5, 31, 15, 16, 44],
              type: "poly",
            },
            position: {
              lat: item.destinationLatitude,
              lng: item.destinationLongitude,
            },
            title:
              "ID: " +
              item.id +
              "\nStatus: " +
              item.status +
              "\nUTC: " +
              item.acceptedAt +
              "\nA: " +
              item.boardAddress +
              "\nB: => " +
              item.destinationAddress,
            //icon: {
            //url: "/img/map-marker.png",
            //scaledSize: new google.maps.Size(40, 40),
            //},
            //colorName je poradove cislo podla datumu, rovno pouzite ako label
            label: {
              text: "" + indexMarker + "",
              color: "#000",
              fontSize: "90%",
              fontWeight: "normal",
            },
          });
          // add to array
          destinationMarkers.push(destinationMarker);
          // add to map
          destinationMarker.setMap(map);
          // add to bounds
          bounds.extend(destinationMarker.position);
        }

        //PATH ROAD
        if (this.enableOrderPath) {
          // create path
          var path = new google.maps.Polyline({
            path: [
              {
                lat: item.boardLatitude,
                lng: item.boardLongitude,
              },
              {
                lat: item.destinationLatitude,
                lng: item.destinationLongitude,
              },
            ],
            geodesic: true,
            //strokeColor: "#FF0000",
            strokeColor: this.rangeColor(colorName),
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });
          // add to array
          orderPath.push(path);
          // add to map
          path.setMap(map);
          // add to bounds
          bounds.extend(
            new google.maps.LatLng(item.boardLatitude, item.boardLongitude)
          );
          bounds.extend(
            new google.maps.LatLng(
              item.destinationLatitude,
              item.destinationLongitude
            )
          );
        }

        //heatmapBoardLayer
        if (this.enableBoardHeatmap) {
          //check if heatmapBoardLayerArray already contains LatLng, and if so, increase weight
          var foundBoard = false;
          var existingBoardIndex = 0;
          for (var i = 0; i < heatmapBoardLayerArray.length; i++) {
            if (
              heatmapBoardLayerArray[i].location.lat() == item.boardLatitude &&
              heatmapBoardLayerArray[i].location.lng() == item.boardLongitude
            ) {
              //heatmapBoardLayerArray[i].weight++;
              foundBoard = true;
              existingBoardIndex = i;
              break;
            }
          }
          if (!foundBoard) {
            //add LatLng to heatmapBoardLayer with weight
            heatmapBoardLayerArray.push({
              location: new google.maps.LatLng(
                item.boardLatitude,
                item.boardLongitude
              ),
              weight: 1,
            });
            bounds.extend(
              new google.maps.LatLng(item.boardLatitude, item.boardLongitude)
            );
          } else {
            //increase weight
            heatmapBoardLayerArray[existingBoardIndex].weight++;
            //add LatLng to heatmapBoardLayer with weight
            //heatmapBoardLayerArray.push({location: new google.maps.LatLng( item.boardLatitude, item.boardLongitude ), weight: foundBoard,});
          }
        }
        //console.log("heatmapBoardLayerArray", heatmapBoardLayerArray);
        //heatmapDestinationLayer
        if (this.enableDestinationHeatmap) {
          //check if heatmapDestinationLayerArray already contains LatLng, and if so, increase weight
          var foundDestination = false;
          var existingDestinationIndex = 0;
          for (var i = 0; i < heatmapDestinationLayerArray.length; i++) {
            if (
              heatmapDestinationLayerArray[i].location.lat() ==
                item.destinationLatitude &&
              heatmapDestinationLayerArray[i].location.lng() ==
                item.destinationLongitude
            ) {
              //heatmapDestinationLayerArray[i].weight++;
              foundDestination = true;
              existingDestinationIndex = i;
              break;
            }
          }
          if (!foundDestination) {
            //add LatLng to heatmapDestinationLayer with weight
            heatmapDestinationLayerArray.push({
              location: new google.maps.LatLng(
                item.destinationLatitude,
                item.destinationLongitude
              ),
              weight: 1,
            });
            bounds.extend(
              new google.maps.LatLng(
                item.destinationLatitude,
                item.destinationLongitude
              )
            );
          } else {
            //increase weight
            heatmapDestinationLayerArray[existingDestinationIndex].weight++;
          }
        }
      }

      //Add boardHeatmap layer
      if (this.enableBoardHeatmap) {
        var heatmapBoardLayer = new google.maps.visualization.HeatmapLayer({
          data: heatmapBoardLayerArray,
          map: map,
          radius: this.heatmapRadius,
          gradient: this.heatmapBoardGradient,
        });
        heatmapBoardLayer.setMap(map);
      }
      //Add destinationHeatmap layer
      if (this.enableDestinationHeatmap) {
        var heatmapDestinationLayer =
          new google.maps.visualization.HeatmapLayer({
            data: heatmapDestinationLayerArray,
            map: map,
            radius: this.heatmapRadius,
            gradient: this.heatmapDestinationGradient,
          });

        heatmapDestinationLayer.setMap(map);
      }
      //Ak nie je bounds definovany, tak sa nastavi default
      this.mapBounds = bounds;
      //Idealne je aby sa to zoomovalo hned, ale v tom case to este nie je vyrenderovane
      this.fitBoundsMap();
      this.boardMarkers = boardMarkers;
      this.orderPath = orderPath;
      this.heatmapBoardLayer = heatmapBoardLayer;
      this.heatmapDestinationLayer = heatmapDestinationLayer;
    },

    fitBoundsMap() {
      //spomalenie kvôli renderovania a vyzera to tak lepsie
      var map = this.map;
      var bounds = this.mapBounds;

      var centerOfMap = this.getCookie("centerOfMap")
        ? JSON.parse(this.getCookie("centerOfMap"))
        : {};
      //ak sú bound definované, tak sa nastavia, ak nie, tak sa nastavi center mapy
      if (bounds) {
        //console.log("bounds final x", bounds);
        setTimeout(function () {
          map.fitBounds(bounds);
        }, 1000);
      } else {
        //console.log("centerOfMap", centerOfMap);
        map.setCenter(centerOfMap);
        map.setZoom(8);
        this.mapCenter = centerOfMap;
      }
    },
    changeBoardGradient() {
      if (this.heatmapBoardLayer) {
        this.heatmapBoardGradient =
          this.heatmapBoardGradient == null ? this.heatmapGradientBlue : null;
      }
      this.heatmapBoardLayer.set("gradient", this.heatmapBoardGradient);
    },

    changeDestinationGradient() {
      if (this.heatmapDestinationLayer) {
        this.heatmapDestinationGradient =
          this.heatmapDestinationGradient == null
            ? this.heatmapGradientBlue
            : null;
        this.heatmapDestinationLayer.set(
          "gradient",
          this.heatmapDestinationGradient
        );
      }
    },

    changeRadius() {
      this.heatmapRadius =
        this.heatmapRadius == null ? 30 : this.heatmapRadius == 30 ? 50 : null;
      if (this.heatmapBoardLayer) {
        //set and remember radius value in this.heatmapRadius
        this.heatmapBoardLayer.set("radius", this.heatmapRadius);
      }
      if (this.heatmapDestinationLayer) {
        this.heatmapDestinationLayer.set("radius", this.heatmapRadius);
      }
    },

    changeOpacity() {
      if (this.heatmapBoardLayer) {
        this.heatmapOpacity =
          this.heatmapOpacity == null
            ? 0.8
            : this.heatmapOpacity == 0.8
            ? 0.2
            : null;
        this.heatmapBoardLayer.set("opacity", this.heatmapOpacity);
      }
      if (this.heatmapDestinationLayer) {
        this.heatmapDestinationLayer.set("opacity", this.heatmapOpacity);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },
    rangeColor(colorName) {
      switch (colorName) {
        case 1:
          return "#00FF00";
          break; //green
        case 2:
          return "#FFD700";
          break; //gold
        case 3:
          return "#996633";
          break; //
        case 4:
          return "#FF8C00";
          break; //
        case 5:
          return "#EE82EE";
          break; //violet
        case 6:
          return "#0000FF";
          break; //blue
        case 7:
          return "#00008B";
          break; //
        case 8:
          return "#ADD8E6";
          break; //
        case 9:
          return "#87CEEB";
          break; //
        case 10:
          return "#FF0000";
          break; //red
        case 11:
          return "#ffa500";
          break; //orange
        case 12:
          return "#800080";
          break; //purple
        case 13:
          return "#FFFF00";
          break; //yellow
        case 14:
          return "#C0C0C0";
          break; //silver
        case 15:
          return "#006400";
          break; //
        case 16:
          return "#90EE90";
          break; //
        case 17:
          return "#FF99CC";
          break; //
        case 18:
          return "#F5F5DC";
          break; //beige
        case 19:
          return "#696969";
          break; //
        case 20:
          return "#E52B50";
          break; //amaranth
        case 21:
          return "#FFBF00";
          break; //amber
        case 22:
          return "#007FFF";
          break; //azure
        case 23:
          return "#CD7F32";
          break; //bronze
        case 24:
          return "#DE3163";
          break; //cerise
        case 25:
          return "#7B3F00";
          break; //chocolate
        case 26:
          return "#0047AB";
          break; //cobalt blue
        case 27:
          return "#F88379";
          break; //coral
        case 28:
          return "#50C878";
          break; //emerald
        case 29:
          return "#4B0082";
          break; //indigo
        case 30:
          return "#00A86B";
          break; //jade
        case 31:
          return "#B57EDC";
          break; //lavender
        case 32:
          return "#BFFF00";
          break; //lime
        case 33:
          return "#FF00FF";
          break; //magenta
        case 34:
          return "#800000";
          break; //maroon
        case 35:
          return "#000080";
          break; //navy blue
        case 36:
          return "#FF2400";
          break; //scarlet
        case 37:
          return "#A7FC00";
          break; //spring bud
        case 38:
          return "#008080";
          break; //teal
        case 39:
          return "#FFC0CB";
          break; //pink
        case 40:
          return "#D1E231";
          break; //pear
        case 41:
          return "#00FF10";
          break; //green
        case 42:
          return "#FFD710";
          break; //gold
        case 43:
          return "#996643";
          break; //
        case 44:
          return "#FF8C10";
          break; //
        case 45:
          return "#EE82FE";
          break; //violet
        case 46:
          return "#0000EF";
          break; //blue
        case 47:
          return "#00009B";
          break; //
        case 48:
          return "#ADD8F6";
          break; //
        case 49:
          return "#87CEFB";
          break; //
        case 50:
          return "#FF0010";
          break; //red
        case 51:
          return "#ffa510";
          break; //orange
        case 52:
          return "#800090";
          break; //purple
        case 53:
          return "#FFFF10";
          break; //yellow
        case 54:
          return "#C0C0D0";
          break; //silver
        case 55:
          return "#006410";
          break; //
        case 56:
          return "#90EE80";
          break; //
        case 57:
          return "#FF99DC";
          break; //
        case 58:
          return "#F5F5EC";
          break; //beige
        case 59:
          return "#696979";
          break; //
        case 60:
          return "#E52B60";
          break; //amaranth
        case 61:
          return "#FFBF10";
          break; //amber
        case 62:
          return "#007FEF";
          break; //azure
        case 63:
          return "#CD7F42";
          break; //bronze
        case 64:
          return "#DE3173";
          break; //cerise
        case 65:
          return "#7B3F10";
          break; //chocolate
        case 66:
          return "#0047BB";
          break; //cobalt blue
        case 67:
          return "#F88389";
          break; //coral
        case 68:
          return "#50C878";
          break; //emerald
        case 69:
          return "#4B0082";
          break; //indigo
        case 70:
          return "#00A86B";
          break; //jade
        case 71:
          return "#B57EDC";
          break; //lavender
        case 72:
          return "#BFFF00";
          break; //lime
        case 73:
          return "#FF00FF";
          break; //magenta
        case 74:
          return "#800000";
          break; //maroon
        case 75:
          return "#000080";
          break; //navy blue
        case 76:
          return "#FF2400";
          break; //scarlet
        case 77:
          return "#A7FC00";
          break; //spring bud
        case 78:
          return "#008080";
          break; //teal
        case 79:
          return "#FFC0CB";
          break; //pink
        case 80:
          return "#D1E231";
          break; //pear
        default:
          return "#FF6600";
      }
    },
    generateMarkers() {
      /*
      Ikonu urcuje "Work status", "Order status" a cas od posledneho zaznamu polohy
      WorkStatus: ONLINE, ON_BREAK, OFFLINE (to sa zrejme nikdy nezobrazi ale keby nahodou)
      OrderStatus: ACCEPTED, WAITING, IN_PROGRESS, FINISHED => alebo BUSY (ked sa pouzije zjednodusene zobrazenie farieb)
      Special status ked sa nieco udeje: DELAY, WARNING
      */
      var iconImage;
      var color1 = "#000000";
      var color2 = "#ffcc00";
      var color3 = "#ffffff";
      var markerName;
      var svg;
      var tempMarkerImage = [];

      for (let i = 0; i < 10; i++) {
        markerName = "";
        //console.log(i, "x");
        switch (i) {
          case 0:
            markerName = "BOARDDEFAULT";
            color2 = "#EEEEEE";
            break;
          case 1:
            markerName = "BOARDOPEN";
            color2 = "#81C784";
            break;
          case 2:
            markerName = "BOARDPROGRESS";
            color2 = "#43A047";
            break;
          case 3:
            markerName = "BOARDFINISHED";
            color2 = "#00FF00";
            break;
          case 4:
            markerName = "BOARDCANCELLED";
            color2 = "#666666";
            break;
          case 5:
            markerName = "DESTINATIONDEFAULT";
            color2 = "#DDDDDD";
            break;
          case 6:
            markerName = "DESTINATIONOPEN";
            color2 = "#E57373";
            break;
          case 7:
            markerName = "DESTINATIONPROGRESS";
            color2 = "#E53935";
            break;
          case 8:
            markerName = "DESTINATIONFINISHED";
            color2 = "#FF0000";
            break;
          case 9:
            markerName = "DESTINATIONCANCELLED";
            color2 = "#333333";
            break;
          default:
            markerName = "XX";
            color2 = "#FFFFFF";
            break;
        }
        //console.log("markerName: ", markerName, " - color2: ", color2);
        iconImage = "";
        svg =
          '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="31px" height="44px" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 502 713" xmlns:xlink="http://www.w3.org/1999/xlink">';
        svg =
          svg +
          '<path fill="' +
          color1 +
          '" d="M251 0c-138,0 -251,112 -251,251 0,189 251,462 251,462 0,0 251,-273 251,-462 0,-138 -112,-251 -251,-251z"/>';
        svg = svg + '<circle fill="' + color2 + '" cx="251" cy="251" r="235"/>';
        svg =
          svg +
          '<path fill="' +
          color3 +
          '" d="M461 357l-420 0c-16,-32 -25,-68 -25,-106 0,-38 9,-73 25,-105l420 0c16,32 25,67 25,105 0,38 -9,74 -25,106z"/>';
        svg = svg + "</svg>";
        iconImage = "data:image/svg+xml;base64," + btoa(svg);
        tempMarkerImage[markerName] = iconImage;
      }
      this.markerImageSet = tempMarkerImage;
    },
  },
};
</script>
