<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <!--<v-toolbar-title>{{ $t("OrderListView.page_title") }}</v-toolbar-title>-->
      <v-toolbar-title>{{ $t("ReportStatusHour.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fa-chart-pie</v-icon>
    </v-toolbar>
    <v-row justify="end">
      <v-spacer></v-spacer>
      <v-col v-show="$vuetify.breakpoint.smAndDown" cols="12" md="3">
        <v-switch
          v-model="mobileBreakpointSwitch"
          :label="$t('ReportStatusHour.mobileBreakpointSwitch_label')"
          hide-details
        ></v-switch> </v-col
      ><v-col cols="12" md="3">
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          :label="$t('ReportStatusHour.selectedHeaders_label')"
          multiple
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text caption"
              >(+{{ selectedHeaders.length - 2 }})</span
            >
          </template>
        </v-select></v-col
      >
    </v-row>
    <v-row class="my-1" align="center">
      <v-col cols="12" md="2">
        <v-switch
          v-model="onlyRealized"
          :label="$t('ReportStatusHour.onlyRealized_label')"
          hide-details
        ></v-switch>
      </v-col>
      <v-col cols="12" md="8">
        <datetime-range-picker filled v-model="dateRange" eager>
        </datetime-range-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="info" large @click="getAll()">
          <v-icon left dark> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col></v-row
    >
    <v-data-table
      :loading="loadingData"
      :headers="showHeaders"
      :items="gridItems"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, 250, -1],
      }"
      class="elevation-1"
      :search="filter.search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      dense
      :mobile-breakpoint="mobileBreakpointTable"
    >
      <template v-slot:[`body.prepend`]>
        <tr>
          <td :hidden="dateHidden">
            <v-text-field
              v-model="filter.date"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportStatusHour.dateRange')"
            ></v-text-field>
          </td>
          <td :hidden="PREREGISTEREDHidden"></td>
          <td :hidden="TRANSFEREDHidden"></td>
          <td :hidden="NEWHidden"></td>
          <td :hidden="PLANNEDHidden"></td>
          <td :hidden="PENDINGHidden"></td>
          <td :hidden="ACCEPTEDHidden"></td>
          <td :hidden="WAITINGHidden"></td>
          <td :hidden="IN_PROGRESSHidden"></td>
          <td :hidden="FINISHEDHidden"></td>
          <td :hidden="CHARGEDHidden"></td>
          <td :hidden="SETTLEDHidden"></td>
          <td :hidden="ARCHIVEDHidden"></td>
          <td :hidden="CANCELLED_PREREGISTEREDHidden"></td>
          <td :hidden="CANCELLED_NEWHidden"></td>
          <td :hidden="CANCELLED_PLANNEDHidden"></td>
          <td :hidden="CANCELLED_PENDINGHidden"></td>
          <td :hidden="CANCELLED_ACCEPTEDHidden"></td>
          <td :hidden="CANCELLED_WAITINGHidden"></td>
          <td :hidden="CANCELLED_IN_PROGRESSHidden"></td>
          <td :hidden="CANCELLED_FINISHEDHidden"></td>
          <td :hidden="CANCELLED_CHARGEDHidden"></td>
          <td :hidden="CANCELLED_SETTLEDHidden"></td>
          <td :hidden="CANCELLED_SUMHidden"></td>
        </tr>
      </template>
      <!--      <template #item="{ item }">
        <tr>
          <td
            style="border-left: 5px solid red"
            v-for="(col, key) in item"
            :key="key"
          >
            {{ item }}
          </td>
        </tr>
      </template>-->
    </v-data-table>
    <v-spacer></v-spacer>
    <br />
    <!--<download-excel
      :data="gridItems"
      worksheet="txm_export"
      name="txm_expor.xls"
      ><v-btn color="info" outlined> Download Data</v-btn>
    </download-excel>-->
    <export-data
      :jsonData="gridItems"
      :jsonFields="jsonFields"
      :outlined="true"
      dense
    >
    </export-data>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }} </v-snackbar
    ><br />
    <LineChart :chartData="chartData" :height="chartHeight" />
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import ExportData from "../components/ExportData.vue";
import LineChart from "../components/LineChart.vue";
import {
  Status,
  Type,
  Source,
  PaymentType,
  StatusColor,
  TypeColor,
  SourceColor,
} from "../enums/OrderEnum";

export default {
  components: {
    ExportData,
    LineChart,
  },
  data() {
    return {
      module: moduleDefaultSettings,
      localeLanguage: "sk",
      Status,
      Type,
      Source,
      PaymentType,
      StatusColor,
      TypeColor,
      SourceColor,
      moment: moment,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      //ID taxisluzby a dispecera
      dispatcherId: null,
      taxiserviceId: null,

      //premenne pre vyhladavanie/filter. Podla: https://codepen.io/rveruna/pen/gVBJmq
      filter: {
        search: "",
        date: "",
      },

      // gridHeaders: [
      //   {
      //     text: "Id",
      //     align: "start",
      //     sortable: false,
      //     value: "id",
      //   },
      //   { text: this.$t("Status"), value: "status" },
      //   { text: this.$t("Type"), value: "type" },
      //   { text: this.$t("Note"), value: "note" },
      //   {
      //     text: this.$t("CreatedAt"),
      //     value: "createdAt",
      //   },
      // ],
      gridItems: [],
      headers: [],
      selectedHeaders: [],
      headersList: [], //lista stlpcov pre vyber stlpcov
      sortBy: null,
      sortDesc: true,
      defaultHiddenColumns: [
        "PREREGISTERED",
        "TRANSFERED",
        "NEW",
        "CANCELLED_PREREGISTERED",
        "CANCELLED_NEW",
        "CANCELLED_PLANNED",
        "CANCELLED_PENDING",
        "CANCELLED_ACCEPTED",
        "CANCELLED_WAITING",
        "CANCELLED_IN_PROGRESS",
        "CANCELLED_FINISHED",
        "CANCELLED_CHARGED",
        "CANCELLED_SETTLED",
        "ARCHIVED",
      ],
      slotList: [
        "date",
        "PREREGISTERED",
        "TRANSFERED",
        "NEW",
        "PLANNED",
        "PENDING",
        "ACCEPTED",
        "WAITING",
        "IN_PROGRESS",
        "FINISHED",
        "CHARGED",
        "SETTLED",
        "ARCHIVED",
        "CANCELLED_PREREGISTERED",
        "CANCELLED_NEW",
        "CANCELLED_PLANNED",
        "CANCELLED_PENDING",
        "CANCELLED_ACCEPTED",
        "CANCELLED_WAITING",
        "CANCELLED_IN_PROGRESS",
        "CANCELLED_FINISHED",
        "CANCELLED_CHARGED",
        "CANCELLED_SETTLED",
        "CANCELLED_SUM",
      ],
      //nazvy stlpcov v tabulke, aby sa skryvali filtrovacie polia
      dateHidden: false,
      PREREGISTEREDHidden: false,
      TRANSFEREDHidden: false,
      NEWHidden: false,
      PLANNEDHidden: false,
      PENDINGHidden: false,
      ACCEPTEDHidden: false,
      WAITINGHidden: false,
      IN_PROGRESSHidden: false,
      FINISHEDHidden: false,
      CHARGEDHidden: false,
      SETTLEDHidden: false,
      ARCHIVEDHidden: false,
      CANCELLED_PREREGISTEREDHidden: false,
      CANCELLED_NEWHidden: false,
      CANCELLED_PLANNEDHidden: false,
      CANCELLED_PENDINGHidden: false,
      CANCELLED_ACCEPTEDHidden: false,
      CANCELLED_WAITINGHidden: false,
      CANCELLED_IN_PROGRESSHidden: false,
      CANCELLED_FINISHEDHidden: false,
      CANCELLED_CHARGEDHidden: false,
      CANCELLED_SETTLEDHidden: false,
      CANCELLED_SUMHidden: false,

      //Zoznam stlpcov pre export do excelu
      jsonFields: {
        Obdobie: { field: "date" },
        PREREGISTERED: { field: "PREREGISTERED" },
        TRANSFERED: { field: "TRANSFERED" },
        NEW: { field: "NEW" },
        PLANNED: { field: "PLANNED" },
        PENDING: { field: "PENDING" },
        ACCEPTED: { field: "ACCEPTED" },
        WAITING: { field: "WAITING" },
        IN_PROGRESS: { field: "IN_PROGRESS" },
        FINISHED: { field: "FINISHED" },
        CHARGED: { field: "CHARGED" },
        SETTLED: { field: "SETTLED" },
        ARCHIVED: { field: "ARCHIVED" },
        CANCELLED_PREREGISTERED: { field: "CANCELLED_PREREGISTERED" },
        CANCELLED_NEW: { field: "CANCELLED_NEW" },
        CANCELLED_PLANNED: { field: "CANCELLED_PLANNED" },
        CANCELLED_PENDING: { field: "CANCELLED_PENDING" },
        CANCELLED_ACCEPTED: { field: "CANCELLED_ACCEPTED" },
        CANCELLED_WAITING: { field: "CANCELLED_WAITING" },
        CANCELLED_IN_PROGRESS: { field: "CANCELLED_IN_PROGRESS" },
        CANCELLED_FINISHED: { field: "CANCELLED_FINISHED" },
        CANCELLED_CHARGED: { field: "CANCELLED_CHARGED" },
        CANCELLED_SETTLED: { field: "CANCELLED_SETTLED" },
        CANCELLED_SUM: { field: "CANCELLED_SUM", type: "decimal" },
      },

      loadingData: false,
      //DateRangePicker - vyber rozhadu datumov pre zoznam
      dateRange: [], //["2021-07-22","2021-07-24"],
      onlyRealized: true,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      chartData: {
        labels: ["-", "-"],
        datasets: [
          {
            label: "-",
            backgroundColor: "#f87979",
            data: [0, 0],
          },
        ],
      },
      chartHeight: 240,
    };
  },

  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
    var headersMap = this.headersMap;
    this.headers = Object.values(headersMap);
    this.getLocaleLanguageInISOFormat();
    //Potrebujeme sa zbavit hodnoty "divide" v headersList. Ale upravu som nepozil vo v-select, musel som hodnotu odstranit z headerMap. Doplnala do v-select oddelovace miesto textu. Mozno sa este zamyslim co s tym urobit.
    //divide bolo treba za ucelom formatovania v tabulke, aby boli oddlene stlpce ciarami
    /*headersMap.forEach((header) => {
      this.headersList.push({
        text: header.text,
        value: header.value,
      });
    });*/
    //this.headersList = Object.values(headersMap);

    this.selectedHeaders = this.headers;

    //Zistenie zoznamu skrytych stlpcov z Cookies
    var tempHiddenColumns = this.getCookie(
      "actualHiddenColumnsReportStatusHour"
    )
      ? JSON.parse(this.getCookie("actualHiddenColumnsReportStatusHour"))
      : [];
    //ak existuje cookie, nacitat ulozenu hodnotu (zoznam stlpcov, ktore sa maju skryt)
    if (tempHiddenColumns.length > 0) {
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !tempHiddenColumns.includes(el.value)
      );
    } else {
      //ak neexistuje cookie, nastavit defaultne hodnoty
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !this.defaultHiddenColumns.includes(el.value)
      );
    }
  },

  mounted() {
    //ID prihlaseneho dispecera
    this.dispatcherId = this.$store.state.user.id;
    //console.log("dispatcherId", dispatcherId);
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    //console.log("mounted - this.dateRange", this.dateRange);

    var onlyRealized = this.getCookie("onlyRealized");
    if (onlyRealized && onlyRealized != null) {
      this.onlyRealized = onlyRealized.toLowerCase() == "true";
    } else {
      this.onlyRealized = true;
    }

    var mobileBreakpointSwitch = this.getCookie("mobileBreakpointSwitch");
    if (mobileBreakpointSwitch && mobileBreakpointSwitch != null) {
      this.mobileBreakpointSwitch =
        mobileBreakpointSwitch.toLowerCase() == "true";
    } else {
      this.mobileBreakpointSwitch = false;
    }

    //Niekedy pri prvom nacitany stranky, nie je hned vyrenderovany sub component, tak pocat 2s. Inak to neviem zatial vyriesit.
    if (this.dateRange[0] == undefined) {
      setTimeout(() => {
        this.getAll();
      }, 2000);
    } else {
      this.getAll();
    }
  },

  watch: {
    showHeaders: function () {
      //ak sa zobrazi hlavicka stlpca, zobrazit aj filter
      var tempShowHearders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          tempShowHearders.some(function (e) {
            return e.value == item;
          })
        ) {
          this[item + "Hidden"] = false;
        } else {
          this[item + "Hidden"] = true;
        }
      });
    },
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },

  computed: {
    showHeaders() {
      //console.log("computed - this.selectedHeaders", this.selectedHeaders);
      //ak sa zobrazi hlavicka stlpca, zobrazit aj filter
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },

    headersMap() {
      return [
        {
          text: this.$t("ReportStatusHour.dateRange"),
          align: "start",
          sortable: true,
          value: "date",
          filter: (value) => {
            if (!this.filter.date) {
              return value;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.date.toLowerCase());
            }
          },
        },
        {
          text: this.$t("orderStatus.PREREGISTERED"),
          value: "PREREGISTERED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.TRANSFERED"),
          value: "TRANSFERED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.NEW"),
          value: "NEW",
          align: "end",
        },
        {
          text: this.$t("orderStatus.PLANNED"),
          value: "PLANNED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.PENDING"),
          value: "PENDING",
          align: "end",
        },
        {
          text: this.$t("orderStatus.ACCEPTED"),
          value: "ACCEPTED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.WAITING"),
          value: "WAITING",
          align: "end",
        },
        {
          text: this.$t("orderStatus.IN_PROGRESS"),
          value: "IN_PROGRESS",
          align: "end",
        },
        {
          text: this.$t("orderStatus.FINISHED"),
          value: "FINISHED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.CHARGED"),
          value: "CHARGED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.SETTLED"),
          value: "SETTLED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.ARCHIVED"),
          value: "ARCHIVED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.CANCELLED_PREREGISTERED"),
          value: "CANCELLED_PREREGISTERED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.CANCELLED_NEW"),
          value: "CANCELLED_NEW",
          align: "end",
        },
        {
          text: this.$t("orderStatus.CANCELLED_PLANNED"),
          value: "CANCELLED_PLANNED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.CANCELLED_PENDING"),
          value: "CANCELLED_PENDING",
          align: "end",
        },
        {
          text: this.$t("orderStatus.CANCELLED_ACCEPTED"),
          value: "CANCELLED_ACCEPTED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.CANCELLED_WAITING"),
          value: "CANCELLED_WAITING",
          align: "end",
        },
        {
          text: this.$t("orderStatus.CANCELLED_IN_PROGRESS"),
          value: "CANCELLED_IN_PROGRESS",
          align: "end",
        },
        {
          text: this.$t("orderStatus.CANCELLED_FINISHED"),
          value: "CANCELLED_FINISHED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.CANCELLED_CHARGED"),
          value: "CANCELLED_CHARGED",
          align: "end",
        },
        {
          text: this.$t("orderStatus.CANCELLED_SETTLED"),
          value: "CANCELLED_SETTLED",
          align: "end",
        },
        {
          text: this.$t("orderStatusExt.CANCELLED_SUM"),
          value: "CANCELLED_SUM",
          align: "end",
        },
      ];
    },
  },

  methods: {
    getAll() {
      //Ak nie je povoleny modul s reportmi, nenaciata ziadne data
      if (this.module.report == false) {
        this.gridItems = [];
        //this.$router.push({ name: "dashboard" });
        //snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgModuleReportNotEnabled");
        this.snackbar.color = "error";
        return;
      }
      //console.log("getAll - dateRange ", this.dateRange);
      //Ulozit si nastavenie rozsahu datumov do cookies
      //this.setCookie("dateRange", JSON.stringify(this.dateRange), 1);
      //console.log("getAll - setCookie - dateRange",JSON.stringify(this.dateRange));
      this.setCookie("onlyRealized", this.onlyRealized, 30);
      this.setHiddenColumnListCookie();
      //console.log("getAll - setCookie - showHeadersReportStatusHour", JSON.stringify(this.showHeaders));
      var localeLanguage = this.localeLanguage;
      //console.log("getAll - localeLanguage", localeLanguage);
      var dayName;
      if (this.checkDateRange() == false) {
        return;
      }
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/report/order-status-hours?from=${this.dateRange[0]}&to=${this.dateRange[1]}&onlyRealized=${this.onlyRealized}`
        )
        .then((response) => {
          //console.log("response", response);
          this.loadingData = false;
          var tempResponseData = response.data;
          //order by date ASC
          tempResponseData.sort((a, b) => {
            if (a.date < b.date) {
              return -1;
            }
            if (a.date > b.date) {
              return 1;
            }
            return 0;
          });
          //Doplnenie dalsich udajov vypocitanych nizsie do zakladneho zoznamu
          var tempModifiedData = [];
          //console.log("tempResponseData", tempResponseData);
          //console.log("tempModifiedData", tempModifiedData);
          var cancelledSum;
          //Uprava dat pre graf
          var graphData = {};
          var graphDataValues = {};
          var graphLabels = [];
          var graphDatasetDataSettled = [];
          var graphDatasetDataCancelled = [];
          tempResponseData.forEach(function (item) {
            //console.log("item", item);
            cancelledSum = 0;
            if (item.CANCELLED_PREREGISTERED) {
              cancelledSum += item.CANCELLED_PREREGISTERED;
            }
            if (item.CANCELLED_NEW) {
              cancelledSum += item.CANCELLED_NEW;
            }
            if (item.CANCELLED_PLANNED) {
              cancelledSum += item.CANCELLED_PLANNED;
            }
            if (item.CANCELLED_PENDING) {
              cancelledSum += item.CANCELLED_PENDING;
            }
            if (item.CANCELLED_ACCEPTED) {
              cancelledSum += item.CANCELLED_ACCEPTED;
            }
            if (item.CANCELLED_WAITING) {
              cancelledSum += item.CANCELLED_WAITING;
            }
            if (item.CANCELLED_IN_PROGRESS) {
              cancelledSum += item.CANCELLED_IN_PROGRESS;
            }
            if (item.CANCELLED_FINISHED) {
              cancelledSum += item.CANCELLED_FINISHED;
            }
            if (item.CANCELLED_CHARGED) {
              cancelledSum += item.CANCELLED_CHARGED;
            }
            if (item.CANCELLED_SETTLED) {
              cancelledSum += item.CANCELLED_SETTLED;
            }
            item.CANCELLED_SUM = cancelledSum;
            //console.log("item.date", item.date);
            var dateString = item.date.substring(0, 10);
            //console.log("dateString", dateString);
            //get name of day from item.date
            dayName = new Date(dateString).toLocaleDateString(localeLanguage, {
              weekday: "long",
            });
            //console.log("dayName", dayName);
            tempModifiedData.push(item);
            //Graph
            graphLabels.push(item.date);
            graphDatasetDataSettled.push(item.SETTLED);
            graphDatasetDataCancelled.push(item.CANCELLED_SUM);
          });
          //console.log("tempModifiedData", tempModifiedData);
          this.gridItems = tempModifiedData;
          //Graph
          graphData.labels = graphLabels;
          graphData.datasets = [];
          graphDataValues = {};
          graphDataValues.data = graphDatasetDataSettled;
          graphDataValues.label = "Zúčtované";
          graphDataValues.borderColor = "#26a69a";
          graphDataValues.backgroundColor = "#26a69a";
          graphData.datasets.push(graphDataValues);
          graphDataValues = {};
          graphDataValues.data = graphDatasetDataCancelled;
          graphDataValues.label = "Zrušené";
          graphDataValues.borderColor = "#f87979";
          graphDataValues.backgroundColor = "#f87979";
          graphData.datasets.push(graphDataValues);
          this.chartData = Object.assign({}, graphData);
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    checkDateRange() {
      //dateRange moze byt iba v rozsahu 2 mesiacov
      if (this.dateRange[0] == null || this.dateRange[1] == null) {
        alert("Zadajte rozsah dátumov");
        return false;
      } else {
        var dateFrom = new Date(this.dateRange[0]);
        var dateTo = new Date(this.dateRange[1]);
        var diffTime = Math.abs(dateTo - dateFrom);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        //console.log("diffDays", diffDays);
        if (diffDays > 63) {
          alert("Rozsah dátumov môže byť maximálne 2 mesiace");
          return false;
        } else {
          return true;
        }
      }
    },
    setHiddenColumnListCookie() {
      //aktualny zoznam poli, ktore sa nemaju zobrazit, ulozit do cookies
      var actualHiddenColumns = [];
      //compare slotList and showHeaders
      var tempShowHeaders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          !tempShowHeaders.some(function (e) {
            return e.value == item;
          })
        ) {
          actualHiddenColumns.push(item);
        }
      });
      this.setCookie(
        "actualHiddenColumnsReportStatusHour",
        JSON.stringify(actualHiddenColumns),
        30
      );
    },

    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //Vymazanie cookie
    eraseCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    getLocaleLanguageInISOFormat() {
      var localeLanguage = this.$store.state.appLocale;
      if (localeLanguage == "en" || localeLanguage == "us") {
        localeLanguage = "en-US";
      } else if (localeLanguage == "cs" || localeLanguage == "cz") {
        localeLanguage = "cs-CZ";
      } else if (localeLanguage == "sk") {
        localeLanguage = "sk-SK";
      } else {
        localeLanguage = "en-US";
      }
      this.localeLanguage = localeLanguage;
      //console.log("localeLanguage", localeLanguage);
      //console.log("this.localeLanguage", this.localeLanguage);
    },
  },
};
</script>
